import { EncryptionService } from "../domain/EncryptionService";
import { ISecureCache } from "./ISecureCache";

export abstract class BaseSecureCache implements ISecureCache {
  protected encryptionKey?: Uint8Array = undefined;

  public async getCacheOrResolve(
    cacheKey: string,
    resolveFn: () => Promise<Uint8Array>,
  ): Promise<Uint8Array> {
    await this.initialize();

    if (!this.cacheKeyExists(cacheKey)) {
      await this.putCacheAndResolve(cacheKey, resolveFn);
    }

    return this.getCache(cacheKey) as Promise<Uint8Array>;
  }

  public async getCache(cacheKey: string): Promise<Uint8Array | null> {
    await this.initialize();

    if (!this.cacheKeyExists(cacheKey)) {
      return Promise.resolve(null);
    }

    const item = (await this.getItem(cacheKey)) as string;
    return EncryptionService.decryptBytes(
      item,
      this.encryptionKey as Uint8Array,
    );
  }

  public async putCache(key: string, value: Uint8Array): Promise<void> {
    await this.putCacheAndResolve(key, () => Promise.resolve(value));
  }

  public abstract clearCache(): void;
  protected abstract initialize(): Promise<void>;
  protected abstract getItem(cacheKey: string): Promise<string | null>;
  protected abstract putCacheAndResolve(
    cacheKey: string,
    resolveFn: () => Promise<Uint8Array>,
  ): Promise<void>;
  public abstract cacheKeyExists(cacheKey: string): boolean;
}
