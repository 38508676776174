import { DependencyInjectionUtils } from "../../util/DependencyInjectionUtils";
import { IWebParser } from "./IWebParser";
import { SmartCaptureModel } from "./SmartCaptureModel";

export class SmartCaptureWebParser {
  constructor(private readonly webParser: IWebParser) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async applyAutoTask(model: SmartCaptureModel) {
    if (model.task || !model.urls || !model.urls[0]) {
      return;
    }
    const title = await this.webParser.fetchTitle(model.urls[0]);
    model.task = "Web: " + title;
  }
}
