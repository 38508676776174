import { SingleRepository } from "../repository/SingleRepository";
import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { EmailHelper } from "../util/EmailHelper";
import { EncryptionPasswordService } from "./EncryptionPasswordService";
import { UserModel } from "./domainModels/UserModel";

export class UserService {
  public static saltGenerationRaceConditionDelay = 3000; // Reduced in tests

  constructor(
    private readonly userRepository: SingleRepository<UserModel>,
    private readonly encryptionPasswordService: EncryptionPasswordService,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async userExists(): Promise<boolean> {
    const user = await this.userRepository.tryGet();
    return !!user;
  }

  public async createNewUser(
    email: string,
    encryptionPassword: string,
  ): Promise<void> {
    this.encryptionPasswordService.validatePasswordRequirements(
      encryptionPassword,
    );

    const user = await this.createUser(email, encryptionPassword);
    await this.encryptionPasswordService.storeEncryptionPasswordInCache(
      email,
      encryptionPassword,
      user.encryptedDataEncryptionKeyTimestamp,
    );
  }

  private async createUser(
    email: string,
    password: string,
  ): Promise<UserModel> {
    EmailHelper.validateEmailFormat(email);

    let userModel = new UserModel();
    userModel.email = email;
    userModel =
      await this.encryptionPasswordService.generateEncryptionKeysInUserModel(
        email,
        password,
        userModel,
      );
    await this.userRepository.save(userModel);

    // wait for other process to reduce the chance for a race condition
    await new Promise((resolve) =>
      setTimeout(resolve, UserService.saltGenerationRaceConditionDelay),
    );
    // On a race condition the cached userRepository should detect a new timestamp and return a different (but consistent) user
    return await this.userRepository.get();
  }
}
