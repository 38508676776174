import { DependencyInjectionUtils } from "../../../util/DependencyInjectionUtils";
import { TaskService } from "../../TaskService";
import { FormattingService } from "../FormattingService";
import { IExecutableBotCommand } from "../IBotCommand";
import { IBotCommandResult } from "../IBotCommandResult";

export class ListMaybeBotQuery implements IExecutableBotCommand {
  public static staticCommandName = "lm";
  public readonly commandName = ListMaybeBotQuery.staticCommandName;
  public readonly description = "List task marked as maybe";
  public readonly smartButtonText = "List maybe";

  constructor(
    private readonly formattingService: FormattingService,
    private readonly taskService: TaskService,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async execute(): Promise<IBotCommandResult> {
    const tasks = await this.taskService.getMaybeTasks();
    const feedback = await this.formattingService.formatTaskListByProject(
      tasks,
      false,
    );

    return {
      commandName: this.commandName,
      feedback,
    };
  }

  // Can't test this in integration
  /* istanbul ignore next */
  public async getAutoCompleteKeywords(
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    commandInput: string,
  ): Promise<string[]> {
    return Promise.resolve([]);
  }
}
