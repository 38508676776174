import { TaskModel } from "../domainModels/TaskModel";

export class SmartCaptureModel {
  public task: string;
  public contexts: string[];
  public projects: string[];
  public tags: string[];
  public outcomes: string[];
  public urls: string[];

  constructor(
    task: string,
    contexts: string[],
    projects: string[],
    tags: string[],
    outcomes: string[],
    urls: string[],
  ) {
    this.task = task;
    this.contexts = contexts;
    this.projects = projects;
    this.tags = tags;
    this.outcomes = outcomes;
    this.urls = urls;
  }

  public mapToTaskModel() {
    const taskModel = new TaskModel(this.task, this.projects, this.contexts);
    taskModel.links = this.urls;
    taskModel.tags = this.tags;
    return taskModel;
  }
}
