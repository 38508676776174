import { IIdentifiable } from "../domain/domainModels/IIdentifiable";
import { IRepository } from "../domain/IRepository";
import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { timestamp } from "../domain/Types";
import { TechnicalError } from "../domain/errors/TechnicalError";
import { AbstractRepository } from "./AbstractRepository";

export class AppendOnlyRepository<T extends IIdentifiable>
  extends AbstractRepository<T>
  implements IRepository<T>
{
  private seenModels: T[] = [];

  constructor(private readonly repository: IRepository<T>) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
    super();
  }

  public async save(model: T): Promise<timestamp> {
    let existingModel;
    if (this.seenModels.length == 0) {
      existingModel = await this.tryGetByUuid(model.uuid);
    } else {
      existingModel = this.seenModels.find((i) => i.uuid === model.uuid);
    }

    /* istanbul ignore if */ // Error contition does not happen during integration
    if (existingModel) {
      throw new TechnicalError(
        "An AppendOnlyRepository cannot update data with uuid: " + model.uuid,
      );
    }

    this.seenModels.push(model);
    return this.repository.save(model);
  }

  public deleteAll(): Promise<timestamp> {
    // We may clear an AppendOnlyRepository (for import)
    this.seenModels = [];
    return this.repository.deleteAll();
  }

  /* istanbul ignore next */ // illegal function not called during integration
  public delete(uuid: string): Promise<timestamp> {
    throw new TechnicalError(
      "An AppendOnlyRepository cannot delete data with uuid: " + uuid,
    );
  }

  public async getAll(): Promise<T[]> {
    const models = await this.repository.getAll();
    this.seenModels = models;
    return models;
  }

  /* istanbul ignore next */ // We don't get indivual events currently in production/kintegration
  public override getByUuid(uuid: string): Promise<T> {
    return this.repository.getByUuid(uuid);
  }

  /* istanbul ignore next */ // Currently not used during integration
  public override tryGetByUuid(uuid: string): Promise<T | undefined> {
    return this.repository.tryGetByUuid(uuid);
  }

  /* istanbul ignore next */ // Only Used by caching which is below in the decorator hierarchy
  public getTimestamp(): Promise<timestamp> {
    return this.repository.getTimestamp();
  }

  public override async import(models: T[]): Promise<timestamp> {
    this.seenModels = models;
    return this.repository.import(models);
  }
}
