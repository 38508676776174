// Based on https://www.npmjs.com/package/node-fetch#handling-client-and-server-errors

import { HTTPResponseError } from "./HTTPResponseError";

/*istanbul ignore file */ // Not tested during integration
export class HttpErrorHelper {
  public static async handleErrors(
    fetchAction: () => Promise<Response>,
    url: string,
    method: string,
    body: string,
    headers?: HeadersInit,
  ): Promise<Response> {
    const response = await fetchAction();
    try {
      HttpErrorHelper.checkStatus(response);
      return response;
    } catch (error: unknown) {
      const httpError = error as HTTPResponseError;
      const errorBody = await httpError.response.text();
      httpError.message = httpError.message + "\n" + errorBody;
      httpError.message += `url: ${url} method ${method} body ${body} headers ${headers}`; // Carefull we don't put security sentive headers in the log here
      throw httpError;
    }
  }

  private static checkStatus(response: Response): Response {
    if (response.ok) {
      return response;
    } else {
      throw new HTTPResponseError(response);
    }
  }
}
