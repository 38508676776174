import * as Clone from "clone";
import { IModel } from "../domain/domainModels/IModel";
import { IConstructor } from "../domain/IConstructor";
import { EncryptedDomainEventModel } from "../domain/events/EncryptedDomainEventModel";
import { ContextModel } from "../domain/domainModels/ContextModel";
import { ProjectModel } from "../domain/domainModels/ProjectModel";
import { TaskModel } from "../domain/domainModels/TaskModel";
import { UserModel } from "../domain/domainModels/UserModel";
import { AddTaskEventModel } from "../domain/events/AddTaskEventModel";
import { AddProjectEventModel } from "../domain/events/AddProjectEventModel";
import { CompleteProjectEventModel } from "../domain/events/CompleteProjectEventModel";
import { CompleteTaskEventModel } from "../domain/events/CompleteTaskEventModel";
import { TaskMaybeEventModel } from "../domain/events/TaskMaybeEventModel";
import { UpdateProjectEventModel } from "../domain/events/UpdateProjectEventModel";
import { UpdateTaskEventModel } from "../domain/events/UpdateTaskEventModel";
import { TechnicalError } from "../domain/errors/TechnicalError";
import { SessionTokenModel } from "../domain/domainModels/SessionTokenModel";

export class ModelCloner {
  public static clone<T>(source: T): T {
    return Clone(source, false);
  }

  /* istanbul ignore next */ // Currently only used in test, so no int coverage
  public static copyToType<T extends IModel>(
    source: IModel,
    type: IConstructor<T>,
  ): T {
    const clonedModel = Clone(source, false);
    const clonedTypedModel = new type();

    return Object.assign(clonedTypedModel, clonedModel);
  }

  /*eslint-disable-next-line @typescript-eslint/no-explicit-any */
  public static isModel(value: any) {
    return (
      value !== null &&
      value !== undefined &&
      typeof value === "object" &&
      Object.prototype.toString.call(value) !== "[object Array]"
    );
  }

  public static HasProp(model: object, prop: string) {
    return Object.prototype.hasOwnProperty.call(model, prop);
  }

  public static cloneToType(untypedObject: IModel): IModel {
    const result = ModelCloner.createTypedInstance(untypedObject);
    const properties = Object.getOwnPropertyNames(untypedObject);
    for (const property of properties) {
      let propValue = untypedObject[property];

      if (this.isModel(propValue)) {
        propValue = ModelCloner.cloneToType(propValue);
      }

      (result as IModel)[property] = propValue;
    }
    return result;
  }

  private static createTypedInstance(untypedModel: IModel) {
    const type = untypedModel.__type;

    // Detect an encrypted model
    if (untypedModel?.modelVersion?.length >= 50) {
      return new EncryptedDomainEventModel();
    }

    // Create the right type here. Properties will be set later.
    switch (type) {
    case "ContextModel":
      return new ContextModel();
    case "ProjectModel":
      return new ProjectModel();
    case "TaskModel":
      return new TaskModel();
    case "UserModel":
      return new UserModel();
    case "AddTaskEventModel":
      return new AddTaskEventModel(new TaskModel());
    case "AddProjectEventModel":
      return new AddProjectEventModel(new ProjectModel());
    case "CompleteProjectEventModel":
      return new CompleteProjectEventModel(untypedModel.uuid);
    case "CompleteTaskEventModel":
      return new CompleteTaskEventModel(untypedModel.uuid);
    case "TaskMaybeEventModel":
      return new TaskMaybeEventModel(
        untypedModel.uuid,
        untypedModel.maybeProperty,
      );
    case "UpdateProjectEventModel":
      return new UpdateProjectEventModel(new ProjectModel());
      /* istanbul ignore next */ // Not used during integration
    case "UpdateTaskEventModel":
      return new UpdateTaskEventModel(new TaskModel());
      /* istanbul ignore next */ // Not used during integration
    case "SessionTokenModel":
      return new SessionTokenModel();
      /* istanbul ignore next */ // Not used during integration
    default:
      throw new TechnicalError(
        "Unknown type: " +
            type +
            " for model " +
            JSON.stringify(untypedModel),
      );
    }
  }
}
