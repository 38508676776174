import { INotifyMessage } from "./INotifyMessage";

type notifySubscriber<T extends INotifyMessage> = (message: T) => Promise<void>;

export class Notifier<T extends INotifyMessage> {
  protected wildcardSubscribers: notifySubscriber<T>[] = [];
  protected subscribers: { [topic: string]: notifySubscriber<T>[] } = {};

  public async publish(message: T): Promise<void> {
    // wildcardSubscribers so we do the validation before processing others
    for (const sub of this.wildcardSubscribers) {
      await sub(message);
    }

    const topic = message.getTopic();
    /* iiistanbul ignore next */
    if (this.subscribers[topic]) {
      for (const sub of this.subscribers[topic]) {
        await sub(message);
      }
    }
  }

  public subscribe(topic: string, sub: notifySubscriber<T>): void {
    if (!this.subscribers[topic]) {
      this.subscribers[topic] = [];
    }
    this.subscribers[topic].push(sub);
  }

  public wildcardSubscribe(sub: notifySubscriber<T>): void {
    this.wildcardSubscribers.push(sub);
  }

  public wildcardRemoveAllSubscribers(): void {
    this.wildcardSubscribers = [];
  }
}
