import { AbstractRepository } from "../repository/AbstractRepository";
import { DateTimeUtils } from "../util/DateTimeUtils";
import { ModelCloner } from "../util/ModelCloner";
import { IRepository } from "./IRepository";
import { timestamp } from "./Types";
import { IIdentifiable } from "./domainModels/IIdentifiable";

// TODO: Move to repository folder
export class InMemoryRepository<T extends IIdentifiable>
  extends AbstractRepository<T>
  implements IRepository<T>
{
  private inMemoryModels: { [uuid: string]: T } = {};
  private currentTimestamp: timestamp = DateTimeUtils.getCurrentTimestamp();

  public getAll(): Promise<T[]> {
    const clonedModels = [];
    for (const model of Object.values(this.inMemoryModels)) {
      clonedModels.push(ModelCloner.clone(model));
    }

    return Promise.resolve(clonedModels);
  }

  public async save(model: T): Promise<timestamp> {
    this.inMemoryModels[model.uuid] = model;
    return this.writeTimestamp();
  }

  public async deleteAll(): Promise<timestamp> {
    this.inMemoryModels = {};
    return this.writeTimestamp();
  }

  public async getTimestamp(): Promise<timestamp> {
    return Promise.resolve(this.currentTimestamp);
  }

  /* istanbul ignore next */ // Not used during integration
  public async delete(uuid: string): Promise<timestamp> {
    delete this.inMemoryModels[uuid];
    return this.getTimestamp();
  }

  private async writeTimestamp(): Promise<timestamp> {
    this.currentTimestamp = DateTimeUtils.getCurrentTimestamp();
    return this.getTimestamp();
  }
}
