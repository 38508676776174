import { Component } from "react";

import { WebCompositionRoot } from "../../WebCompositionRoot";
import { AutoContextService } from "brainsupporter-core/lib/domain/AutoContextService";
import { Notifier } from "brainsupporter-core/lib/domain/pubsub/Notifier";
import { NotifyMessage } from "brainsupporter-core/lib/domain/pubsub/NotifyMessage";
import { BotService } from "brainsupporter-core/lib/domain/bot/BotService";
import { IBotCommandResult } from "brainsupporter-core/lib/domain/bot/IBotCommandResult";

type TaskListState = {
  feedbackItems: string[];
};

type TaskListProps = {
  root: WebCompositionRoot;
};

class TaskList extends Component<TaskListProps, TaskListState> {
  private static alreadyMounted = false;

  botService: BotService;
  autoContextService: AutoContextService;
  notifier: Notifier<NotifyMessage>;

  constructor(props: TaskListProps) {
    super(props);

    this.botService = this.props.root.BotService;
    this.autoContextService = this.props.root.AutoContextService;
    this.notifier = this.props.root.Notifier;

    this.state = {
      feedbackItems: [],
    };
  }

  override async componentDidMount() {
    // TODO: v1.0.3: this should probably happen somewhere else, to prevent it from
    // happening every time the component is mounted. Maybe it should be an application state?
    // https://reactjs.org/blog/2022/03/29/react-v18.html#new-strict-mode-behaviors
    // https://reactjs.org/docs/strict-mode.html#ensuring-reusable-state
    if (TaskList.alreadyMounted) return;
    TaskList.alreadyMounted = true;

    this.notifier.wildcardSubscribe(async () => {
      void this.updateTaskList();
    });

    void this.updateTaskList();
  }

  private async updateTaskList() {
    const listTaskResult = await this.botService.sendText("lt");
    this.setFeedback(listTaskResult);
  }

  // TODO: Do we need a feedback component for use here and in webconsole?
  // TODO: Deduplicate with webconsole.tsx
  private setFeedback(result: IBotCommandResult) {
    let feedbackItems: string[] = [];
    //feedbackItems.push("$ " + this.maskPassword(command));

    const feedbackLines = this.formatFeedback(result.feedback);
    feedbackItems = feedbackItems.concat(feedbackLines);
    this.setState({
      feedbackItems: feedbackItems,
    });
  }

  // TODO: Deduplicate with webconsole.tsx
  private formatFeedback(feedback: string) {
    const formattedFeedback = feedback.replaceAll("\t", "    ");
    return formattedFeedback.split("\n");
  }
  override render() {
    // TODO: Deduplicate with webconsole
    const { feedbackItems } = this.state;
    const feedbackList = feedbackItems.map((feedbackItem, index) => {
      if (feedbackItem == "") {
        return <li key={index}>&nbsp;</li>;
      }
      return <li key={index}>{feedbackItem}</li>;
    });
    return (
      <div>
        <hr />
        <ul id="feedback-array">{feedbackList}</ul>
      </div>
    );
  }
}

export default TaskList;
