import { DependencyInjectionUtils } from "../../../util/DependencyInjectionUtils";
import { AutoCompleteService } from "../../AutoCompleteService";
import { SmartCaptureService } from "../../SmartCapture/SmartCaptureService";
import { IExecutableBotCommand } from "../IBotCommand";
import { IBotCommandResult } from "../IBotCommandResult";

export class AddBotCommand implements IExecutableBotCommand {
  public readonly commandName = "add";
  public readonly description = "Add a task";
  public readonly smartButtonText = "Add task";

  public readonly argumentDescription =
    "task <@contexts> <+projects> <urls> <$project goals> <#tags>";

  constructor(
    private readonly smartCaptureService: SmartCaptureService,
    private readonly autoCompleteService: AutoCompleteService,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async execute(input: string): Promise<IBotCommandResult> {
    const feedback = await this.smartCaptureService.capture(input);
    return {
      commandName: this.commandName,
      feedback,
    };
  }

  public async getAutoCompleteKeywords(
    commandInput: string,
  ): Promise<string[]> {
    return this.autoCompleteService.getEditAutoCompleteKeywords(commandInput);
  }
}
