import { IIdentifiable } from "../domain/domainModels/IIdentifiable";
import { TechnicalError } from "../domain/errors/TechnicalError";
import { IRepository } from "../domain/IRepository";
import { timestamp } from "../domain/Types";

export abstract class AbstractRepository<T extends IIdentifiable>
implements IRepository<T>
{
  public abstract save(model: T): Promise<timestamp>;
  public abstract deleteAll(): Promise<timestamp>;
  public abstract delete(uuid: string): Promise<timestamp>;
  public abstract getAll(): Promise<T[]>;

  public async tryGetByUuid(uuid: string): Promise<T | undefined> {
    const allItems = await this.getAll(); // This might not be optimal. Override when needed
    return allItems.find((i) => i.uuid === uuid);
  }

  public async getByUuid(uuid: string): Promise<T> {
    const found = await this.tryGetByUuid(uuid);
    /* istanbul ignore if */
    if (!found) {
      throw new TechnicalError(`getByUuid(uuid: ${uuid}) not found`);
    }
    return found;
  }

  // Override to optimize
  public async import(models: T[]): Promise<timestamp> {
    for (const model of models) {
      await this.save(model);
    }
    return this.getTimestamp();
  }

  public abstract getTimestamp(): Promise<timestamp>;
}
