export class VersionUtils {
  // The actual version gets replaced in the javascript buildoutput by a script
  private static readonly versionString: string = "[replaceByBuild]";

  public static getFullVersion(): string {
    return VersionUtils.versionString;
  }

  /* istanbul ignore next */ // Currently not used during integration test
  public static getVersionNumber() {
    return VersionUtils.getFullVersion().split("-")[0];
  }

  /* istanbul ignore next */ // Currently not used during integration test
  public static isProduction() {
    return VersionUtils.getFullVersion().split("-").length == 2;
  }

  /* istanbul ignore next */ // Currently not used during integration test
  public static isCanary() {
    return VersionUtils.getFullVersion().includes("-canary");
  }

  // Is alos dev
  /* istanbul ignore next */ // Currently not used during integration test
  public static IsAcceptance() {
    return VersionUtils.getFullVersion().includes("-master");
  }

  /* istanbul ignore next */ // Currently not used during integration test
  public static isDev() {
    return !VersionUtils.isProduction() && !VersionUtils.isCanary();
  }
}
