import { Component } from "react";
import "./Loader.css";

type LoaderProps = {
  loading: boolean;
};

class Loader extends Component<LoaderProps, unknown> {
  constructor(props: LoaderProps) {
    super(props);
  }

  override render() {
    if (this.props.loading) {
      return (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      );
    } else {
      return;
    }
  }
}

export default Loader;
