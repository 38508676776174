import * as Uuid from "uuid";

import { epoch } from "../Types";
import { IDomainModel } from "./IDomainModel";
import { Validate } from "../validators/Validate";

export class TaskModel implements IDomainModel {
  public modelVersion = 3;
  public __type: string = "TaskModel";

  public uuid: string;
  public task: string;
  public projects: string[];
  public contexts: string[];
  public completed: boolean;
  public completionDateTime?: epoch;
  public creationDateTime?: epoch;
  public maybe: boolean;
  public links: string[];
  public tags: string[];

  protected static expectedProperties = [
    "modelVersion",
    "__type",
    "uuid",
    "task",
    "projects",
    "contexts",
    "completed",
    "completionDateTime",
    "creationDateTime",
    "maybe",
    "links",
    "tags",
  ];

  protected validateExpectedProperties() {
    Validate.expectedProperties(this, TaskModel.expectedProperties);
  }

  constructor(
    task = "",
    projects: string[] = [],
    contexts: string[] = [],
    completed = false,
    links: string[] = [],
    maybe = false,
    tags: string[] = [],
  ) {
    this.uuid = Uuid.v4();
    this.task = task;
    this.projects = projects;
    this.contexts = contexts;
    this.completed = completed;
    this.links = links;
    this.maybe = maybe;
    this.tags = tags;
  }

  public validate() {
    this.validateExpectedProperties();
    Validate.Uuidv4(this.uuid);
    Validate.number(this.modelVersion);
    Validate.string(this.task);
    Validate.stringArray(this.projects);
    Validate.stringArray(this.contexts);
    Validate.boolean(this.completed);
    Validate.boolean(this.completed);
    Validate.optionalEpoch(this.completionDateTime);
    Validate.optionalEpoch(this.creationDateTime);
    Validate.stringArray(this.links);
    Validate.boolean(this.maybe);
    Validate.stringArray(this.tags);
  }
}
