import { EncryptionService } from "../domain/EncryptionService";
import { BaseSecureCache } from "./BaseSecureCache";

export class InMemorySecureCache extends BaseSecureCache {
  private secureStore: { [cacheKey: string]: Promise<string> } = {};

  /* istanbul ignore next */ // Only used in unit tests
  public clearCache(): void {
    this.secureStore = {};
    this.encryptionKey = undefined;
  }

  protected initialize(): Promise<void> {
    if (!this.encryptionKey) {
      this.encryptionKey = EncryptionService.createRandomBytes(16);
    }

    // Only necessary for return type
    return Promise.resolve();
  }

  protected async putCacheAndResolve(
    cacheKey: string,
    resolveFn: () => Promise<Uint8Array>,
  ): Promise<void> {
    await this.initialize();

    const encryptionKey = this.encryptionKey;

    this.secureStore[cacheKey] = resolveFn().then((value) =>
      EncryptionService.encryptBytes(value, encryptionKey as Uint8Array),
    );
  }

  protected async getItem(cacheKey: string): Promise<string | null> {
    return this.secureStore[cacheKey];
  }

  public cacheKeyExists(cacheKey: string): boolean {
    return !!this.secureStore[cacheKey];
  }
}
