import { DomainEventBus } from "../pubsub/DomainEventBus";
import { IDomainModel } from "../domainModels/IDomainModel";
import { TaskModel } from "../domainModels/TaskModel";
import { AddTaskEventModel } from "../events/AddTaskEventModel";
import { CompleteTaskEventModel } from "../events/CompleteTaskEventModel";
import { TaskMaybeEventModel } from "../events/TaskMaybeEventModel";
import { UpdateTaskEventModel } from "../events/UpdateTaskEventModel";
import { InMemoryRepository } from "../InMemoryRepository";
import { TaskViewModel } from "../viewModels/TaskViewModel";

export class TaskViewGenerator {
  constructor(
    private readonly taskView: InMemoryRepository<TaskViewModel>,
    private readonly eventBus: DomainEventBus,
  ) {
    this.eventBus.subscribe(AddTaskEventModel.eventNameConstant, (event) =>
      this.addTaskEventSubscriber(event),
    );
    this.eventBus.subscribe(UpdateTaskEventModel.eventNameConstant, (event) =>
      this.updateTaskEventSubscriber(event),
    );
    this.eventBus.subscribe(CompleteTaskEventModel.eventNameConstant, (event) =>
      this.completeTaskEventSubscriber(event as CompleteTaskEventModel),
    );
    this.eventBus.subscribe(TaskMaybeEventModel.eventNameConstant, (event) =>
      this.taskMaybeEventSubscriber(event as TaskMaybeEventModel),
    );
  }

  private async addTaskEventSubscriber(event: IDomainModel) {
    const taskModel = (event as AddTaskEventModel).taskModel;
    const viewModel = await this.createViewModel(taskModel);
    await this.taskView.save(viewModel);
  }

  private async createViewModel(taskModel: TaskModel): Promise<TaskViewModel> {
    const allTasks = await this.taskView.getAll();
    const numberOfTasks = allTasks.length;
    const taskViewModel = new TaskViewModel();
    Object.assign(taskViewModel, taskModel);
    taskViewModel.displayId = numberOfTasks + 1;
    return taskViewModel;
  }

  private async updateTaskEventSubscriber(event: IDomainModel) {
    const taskModel = (event as UpdateTaskEventModel).taskModel;
    const oldTaskViewModel = await this.taskView.getByUuid(taskModel.uuid);
    const taskViewModel = new TaskViewModel();
    Object.assign(taskViewModel, taskModel);
    taskViewModel.displayId = oldTaskViewModel.displayId;
    await this.taskView.save(taskViewModel);
  }

  private async completeTaskEventSubscriber(event: CompleteTaskEventModel) {
    const taskViewModel = await this.taskView.getByUuid(event.taskUuid);
    taskViewModel.completed = true;
    taskViewModel.completionDateTime = event.eventDateTime;
    await this.taskView.save(taskViewModel);
  }

  private async taskMaybeEventSubscriber(event: TaskMaybeEventModel) {
    const taskViewModel = await this.taskView.getByUuid(event.taskUuid);
    taskViewModel.maybe = event.maybeProperty;
    await this.taskView.save(taskViewModel);
  }
}
