import { IIdentifiable } from "../domain/domainModels/IIdentifiable";
import { IRepository } from "../domain/IRepository";
import { timestamp } from "../domain/Types";
import { FileSystemUtils } from "../util/FileSystemUtils";
import { Validate } from "../domain/validators/Validate";
import { TechnicalError } from "../domain/errors/TechnicalError";
import { IPageResult } from "./IPageResult";
import { JsonModelRepository } from "./JsonModelRepository";
import { ArrayUtils } from "../util/ArrayUtils";

export class PagedJsonModelRepository<T extends IIdentifiable>
  extends JsonModelRepository<T>
  implements IRepository<T>
{
  public static readonly PageSize = 100;

  /* istanbul ignore next */ // Not used during integration
  public async getPage(page: number): Promise<IPageResult<T>> {
    const pagedFiles = await this.getPageModelFiles(page);

    const cacheCountPromise = this.getCacheCount();
    const modelsInPage = await this.getModels(pagedFiles);

    const pageResult: IPageResult<T> = {
      modelsInPage: modelsInPage,
      cacheCount: await cacheCountPromise,
    };

    return pageResult;
  }

  /* istanbul ignore next */ //disabled
  public override async delete(uuid: string): Promise<timestamp> {
    // Delete does not update the index yet. Currently we don't use delete
    throw new TechnicalError(
      "PagedJsonModelRepository.delete not implemented " + uuid,
    );
  }

  protected override async getModels(files: string[]) {
    try {
      // Without await the try catch won't do anything.
      return await super.getModels(files);
    } catch (error) /* istanbul ignore next */ {
      FileSystemUtils.validateIsNoSuchFileOrDirectoryError(error as Error);
      await this.logService.logErrorMessage(
        "Couldn't find a file. That probably means the file is not there but it is in the index. Check and rebuild indexes",
      );
      await this.validateAndRepairIndexedPages();
      throw error;
    }
  }

  public override async save(model: T): Promise<timestamp> {
    // Save before we index so if we crash in between we have data that can reconstruct the index
    const timestamp = super.save(model);
    await this.appendLastIndexPage(model.uuid);

    return timestamp;
  }

  public async getCacheCount(): Promise<number> {
    const cacheCountFilename = await this.getCacheCountFilename();
    try {
      const cacheCount = await this.fileSystem.readFile(cacheCountFilename);
      return Number(cacheCount);
    } catch (error) {
      FileSystemUtils.validateIsNoSuchFileOrDirectoryError(error as Error);
      await this.writeCacheCount(0);
      return 0;
    }
  }

  public override async import(models: T[]): Promise<timestamp> {
    const timestamp = await super.import(models);

    const allModelFiles = models.map((m) => m.uuid + ".json");
    await this.constructIndexedPages(
      allModelFiles,
      PagedJsonModelRepository.PageSize,
    );
    return timestamp;
  }

  // TODO: Add serverside cache for node. At what level? Repo? CachedRepo? AzureFileSystem? Maybe only immutable files and clear on import?
  // We could cache modelFiles and full indexes as these are immutable until reencrypted. Invalidate with cachecounter?
  // Maybe build buffer first to see what we really need

  /* istanbul ignore next */ // Not used during integration
  private async getPageModelFiles(page: number): Promise<string[]> {
    // Validate to prevent directory traversal attacks
    Validate.number(page);
    const pageSize = PagedJsonModelRepository.PageSize;

    // Pages start at 1
    if (page < 1) {
      throw new TechnicalError("Invalide page(size)");
    }

    const requestedIndex = await this.getIndexPage(page, pageSize);
    if (requestedIndex.length > 0) {
      if (requestedIndex.length < PagedJsonModelRepository.PageSize) {
        // This should be the last page, otherwise the index is corrupt

        // Check by counting index files
        const lastPageNr = await this.getLastPage(pageSize);
        if (page !== lastPageNr) {
          const errMsg =
            "Found a non full page which is not the last. Rebuild the index";
          await this.logService.logErrorMessage(errMsg);
          await this.validateAndRepairIndexedPages();
          throw new TechnicalError(errMsg);
        }
      }

      // If we have an existing page we are done
      return requestedIndex;
    }

    const allModelFiles = await this.getModelFiles();

    // Page out of range, stop here
    if ((page - 1) * pageSize >= allModelFiles.length) {
      return [];
    }

    // So we don't have an index yet. Construct any new indexes and return the page
    // This should not be possible in a normal situation except for initial migrations or errors
    await this.logService.logWarningMessage(
      `Reconstructing indexes for requesting page: ${page} pagesize: ${pageSize}`,
    );

    const pages = await this.constructIndexedPages(allModelFiles, pageSize);
    return pages[page];
  }

  // This code has a race condition when doing simultanous imports. But hard to solve that without a database. Should be rare
  private async constructIndexedPages(
    allModelFiles: string[],
    pageSize: number,
  ) {
    let unindexedFiles = allModelFiles;
    let currentPage = 1;
    const pages: string[][] = [];

    // Delete previous indexes
    await this.fileSystem.removeDataSubDir(this.getIndexPath());
    await this.fileSystem.ensureDirExist(this.getIndexPath());

    do {
      const newPage = unindexedFiles.slice(0, pageSize); // Could be less than the pagesize for the last page
      await this.saveIndexPage(currentPage, pageSize, newPage);
      unindexedFiles = unindexedFiles.slice(newPage.length);
      pages[currentPage] = newPage;
      currentPage++;
    } while (unindexedFiles.length > 0);

    // Invalidate all client caches by incrementing the cachecount
    const cacheCount = await this.getCacheCount();
    await this.writeCacheCount(cacheCount + 1);

    return pages;
  }

  /* istanbul ignore next */ // Not tested during integration
  public async validateAndRepairIndexedPages() {
    const allModelFiles = await this.getModelFiles();
    let indexedFiles: string[] = [];

    let currentPage = 1;
    let currentIndex = [];

    do {
      currentIndex = await this.getIndexPage(
        currentPage,
        PagedJsonModelRepository.PageSize,
      );
      indexedFiles = indexedFiles.concat(currentIndex);
      currentPage++;
    } while (currentIndex.length > 0);

    const extraIndexedFiles = ArrayUtils.difference(
      indexedFiles,
      allModelFiles,
    );
    const unindexedFiles = ArrayUtils.difference(allModelFiles, indexedFiles);

    if (unindexedFiles.length > 0 || extraIndexedFiles.length > 0) {
      await this.logService.logErrorMessage(
        `index out of sync. unindexedFiles: ${unindexedFiles.join(",")} extraIndexedFiles: ${extraIndexedFiles.join(",")} `,
      );

      // Repair indexes
      await this.constructIndexedPages(
        allModelFiles,
        PagedJsonModelRepository.PageSize,
      );
    }
  }

  private async getIndexPage(page: number, pageSize: number) {
    await this.fileSystem.ensureDirExist(this.getIndexPath());

    const indexFile = this.getIndexFile(pageSize, page);

    try {
      const indexFileContent = await this.fileSystem.readFile(indexFile);

      /* istanbul ignore next */ // indexFileContent cannot be empty normally
      if (!indexFileContent) {
        return [];
      }

      const files = indexFileContent.split(/\r?\n/);
      return files;
      // Not used during integration
    } catch (error) /* istanbul ignore next */ {
      FileSystemUtils.validateIsNoSuchFileOrDirectoryError(error as Error);
      return [];
    }
  }

  private async saveIndexPage(
    page: number,
    pageSize: number,
    modelFiles: string[],
  ) {
    /* istanbul ignore next */ // Should be impossible
    if (modelFiles.length > pageSize) {
      throw new TechnicalError("New page has more models than the pagesize");
    }

    /* istanbul ignore next */ // Should be impossible
    if (modelFiles.length === 0) {
      return;
    }

    await this.fileSystem.ensureDirExist(this.getIndexPath());
    const text = modelFiles.join("\n");
    const indexFile = this.getIndexFile(pageSize, page);
    await this.fileSystem.saveTextFile(indexFile, text);
  }

  private async appendLastIndexPage(uuid: string) {
    await this.fileSystem.ensureDirExist(this.getIndexPath());

    const modelFilename = uuid + ".json";
    const pageSize = PagedJsonModelRepository.PageSize;

    let lastPageNr = await this.getLastPage(pageSize);

    // The index is not present reconstruct
    if (lastPageNr === 0) {
      const allModelFiles = await this.getModelFiles();
      await this.constructIndexedPages(allModelFiles, pageSize);
      // We have recontructed the index including the new file, so stop here
      return;
    }

    const lastPage = await this.getIndexPage(lastPageNr, pageSize);

    let newLine = "";

    let doIndexValidation = false;
    /* istanbul ignore next */ // Not used during integration
    if (lastPage.length >= pageSize) {
      // The page is full

      // Do an integrity check on the index and repair if needed
      doIndexValidation = true;

      // Use the next page
      lastPageNr++;
      newLine = modelFilename;
    } else {
      newLine = "\n" + modelFilename;
    }

    const indexFile = this.getIndexFile(pageSize, lastPageNr);
    await this.fileSystem.appendFile(indexFile, newLine);

    /* istanbul ignore if */ // Not tested during integration
    if (doIndexValidation) {
      await this.validateAndRepairIndexedPages();
    }
  }

  private getIndexFile(pageSize: number, pageNr: number) {
    return this.getIndexPath() + `/index${pageSize}-${pageNr}.txt`;
  }

  private async getLastPage(pageSize: number) {
    const indexPath = this.getIndexPath();
    const indexesDir = await this.fileSystem.readDir(indexPath);
    const indexes = indexesDir.filter(
      (f) => f.startsWith(`index${pageSize}-`) && f.endsWith(".txt"),
    );
    return indexes.length;
  }

  private getIndexPath(): string {
    return this.repositoryPath() + "/indexes";
  }

  private async writeCacheCount(cacheCount: number): Promise<void> {
    await this.fileSystem.saveTextFile(
      await this.getCacheCountFilename(),
      cacheCount.toString(),
    );
  }

  private async getCacheCountFilename(): Promise<string> {
    return this.dataFolder() + "/" + this.type + ".cachecount";
  }
}
