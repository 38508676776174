import { IModel } from "../../domain/domainModels/IModel";
import { UserModel } from "../../domain/domainModels/UserModel";
import { ModelCloner } from "../../util/ModelCloner";
import { IMigrator } from "../IMigrator";
import { addUserModelType } from "./scripts/20240729-addUserModelType";

export class UserModelMigrator implements IMigrator<UserModel> {
  public migrate(userModels: IModel[]): UserModel[] {
    const results: UserModel[] = [];
    for (const userModel of userModels) {
      addUserModelType(userModel);
      const clonedUserModel = ModelCloner.cloneToType(userModel) as UserModel;
      clonedUserModel.validate();
      results.push(clonedUserModel);
    }

    return results;
  }
}
