import * as Aesjs from "aes-js";
import * as Crypto from "crypto";

import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { InMemorySecureCache } from "../util/InMemorySecureCache";
import { TextUtils } from "../util/TextUtils";
import { bcryptHash } from "./Types";

export class EncryptionService {
  public static EncryptionType = {
    AES256_CBC_PKCS7: "AES256_CBC_PKCS7",
  };

  private static readonly ivNumberOfBytes = 16;

  constructor(
    private readonly bcryptHash: bcryptHash,
    private readonly inMemorySecureCache: InMemorySecureCache,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public static encrypt(value: string, encryptionKey: Uint8Array): string {
    const valueBytes = TextUtils.stringToBytes(value);
    return EncryptionService.encryptBytes(valueBytes, encryptionKey);
  }

  public static encryptBytes(
    valueBytes: Uint8Array,
    encryptionKey: Uint8Array,
  ): string {
    const iv = this.createRandomBytes(EncryptionService.ivNumberOfBytes);
    const padded = Aesjs.padding.pkcs7.pad(valueBytes);
    const aesCbc = EncryptionService.createCbc(encryptionKey, iv);
    const encryptedBytes = aesCbc.encrypt(padded);
    const ivHex = Aesjs.utils.hex.fromBytes(iv);
    const encryptedHex = Aesjs.utils.hex.fromBytes(encryptedBytes);
    return [ivHex, encryptedHex].join(",");
  }

  public static decrypt(
    encryptedValue: string,
    encryptionKey: Uint8Array,
  ): string {
    const decryptedBytes = EncryptionService.decryptBytes(
      encryptedValue,
      encryptionKey,
    );
    return TextUtils.bytesToString(decryptedBytes);
  }

  public static decryptBytes(
    encryptedValue: string,
    encryptionKey: Uint8Array,
  ): Uint8Array {
    const ivLengthHex = EncryptionService.ivNumberOfBytes * 2;
    const [iv, encryptedString] = encryptedValue.split(","); // Can't test this in integration

    /* istanbul ignore next */
    if (iv.length !== ivLengthHex) {
      throw Error("Invalid IV found in decryptBytes");
    }

    try {
      const ivBytes = Aesjs.utils.hex.toBytes(iv);
      const encryptedBytes = Aesjs.utils.hex.toBytes(encryptedString);
      const aesCbc = EncryptionService.createCbc(encryptionKey, ivBytes);
      const decryptedBytes = aesCbc.decrypt(encryptedBytes);
      const strippedDecryptedBytes = Aesjs.padding.pkcs7.strip(decryptedBytes);
      return new Uint8Array(strippedDecryptedBytes);
    } catch (e) {
      /* istanbul ignore next */ // Hard to test during integration

      // Carefull this is were padding oracle attacks can occur.
      // https://www.skullsecurity.org/2013/padding-oracle-attacks-in-depth
      // Don't make a distinction between padding and other errors

      // If we don't allow an untrusted party to send bytes to decrypt we are not vulnerable, which we currently don't do.

      throw Error("Decryption error. Is the password correct?"); // Don't expose the original error
    }
  }

  public static createRandomBytes(length: number): Uint8Array {
    // Use crypto.getRandomValue when in used in browser
    return Crypto.randomBytes(length);
  }

  // Only used during azure integration which is not always enabled
  /* istanbul ignore next */
  public static computeMD5Buffer(content: string): Buffer {
    // SONAR: Make sure this weak hash algorithm is not used in a sensitive context here.
    const md5Sum = Crypto.createHash("md5"); // NOSONAR
    md5Sum.update(content);
    return md5Sum.digest();
  }

  public static computeMD5(content: string): string {
    const md5Buffer = EncryptionService.computeMD5Buffer(content);
    return md5Buffer.toString("hex");
  }

  private static createCbc(key256: Uint8Array, ivBytes: Uint8Array) {
    const cbc = Aesjs.ModeOfOperation.cbc;
    return new cbc(key256, ivBytes);
  }

  public async convertPasswordToKey(
    email: string,
    password: string,
    salt: string,
  ): Promise<Uint8Array> {
    const cacheKey = `convertPasswordToKey(${email}, ${salt})`;

    return this.inMemorySecureCache.getCacheOrResolve(cacheKey, async () => {
      return this.computePasswordToKey(password, salt);
    });
  }

  public clearCache(): void {
    this.inMemorySecureCache.clearCache();
  }

  public async computePasswordToKey(
    password: string,
    salt: string,
  ): Promise<Uint8Array> {
    // ! Make sure we use a different salt for password validation and key generation
    // Same algorithm but with a different salt

    // The salt contains the workfactor, so that is why we don't need to pass it here
    // See https://security.stackexchange.com/questions/202719/bcrypt-workfactor-for-salt
    const hash = await this.bcryptHash(password, salt);
    // 1 character piece of the salt. Next 31 result of hash.
    return TextUtils.stringToBytes(hash.substr(-32));
  }

  public generateDataEncryptionKey(): Uint8Array {
    return EncryptionService.createRandomBytes(32);
  }
}
