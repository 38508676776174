import { isBrowser, isNode, isWebWorker } from "browser-or-node";

export class BrowserOrNode {
  public static isBrowser(): boolean {
    return isBrowser;
  }

  public static isNode(): boolean {
    return isNode;
  }

  public static isWebWorker(): boolean {
    return isWebWorker;
  }
}
