import { DateTimeUtils } from "../util/DateTimeUtils";
import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { TaskService } from "./TaskService";

export class ContextService {
  constructor(private readonly taskService: TaskService) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async getContextsFromRecentOrActiveTasks(): Promise<string[]> {
    const allTasks = await this.taskService.getTasks();
    const secondsInDays = 60 * 60 * 24 * -30; // Get all contexts up to 30 days ago
    const lastMonth = DateTimeUtils.getEpochOffSetFromNow(secondsInDays);
    const recentOrUncompletedTasks = allTasks.filter(
      (t) =>
        !t.completed ||
        (t.completionDateTime && t.completionDateTime > lastMonth),
    );

    const allContexts = recentOrUncompletedTasks.map((t) => t.contexts).flat();
    const distinctContexts = Array.from(new Set(allContexts)).sort();

    return distinctContexts;
  }

  public async getAutoCompleteContexts(
    onlyWithPrefix = false,
  ): Promise<string[]> {
    const activeContexts = (
      await this.getContextsFromRecentOrActiveTasks()
    ).sort();
    const contextsWithAtSign = ContextService.addAtSign(activeContexts);
    if (onlyWithPrefix) {
      return contextsWithAtSign;
    }
    return activeContexts.concat(contextsWithAtSign);
  }

  public static addAtSign(contexts: string[]) {
    return contexts.map((c) => {
      return "@" + c;
    });
  }
}
