import { DependencyInjectionUtils } from "../../../util/DependencyInjectionUtils";
import { EncryptionPasswordService } from "../../EncryptionPasswordService";
import { FunctionalError } from "../../errors/FunctionalError";
import { IExecutableBotCommand } from "../IBotCommand";
import { IBotCommandResult } from "../IBotCommandResult";
import { ExportBotQuery } from "../queries/ExportBotQuery";

export class ChangeEncryptionPasswordBotCommand
implements IExecutableBotCommand
{
  public static staticCommandName = "cep";
  public readonly description =
    "Change the password used for encrypting all data on the client";
  public readonly smartButtonText = "Change encryption password";

  public readonly argumentDescription = "<password> <password-confirmation>";

  public readonly commandName =
    ChangeEncryptionPasswordBotCommand.staticCommandName;

  constructor(
    private readonly encryptionPasswordService: EncryptionPasswordService,
    private readonly exportBotQuery: ExportBotQuery,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async execute(args: string): Promise<IBotCommandResult> {
    // We don't support passwords with spaces and validate that in the UI.
    const nrOfSpaces = args.split(" ").length - 1;
    if (nrOfSpaces !== 1) {
      throw new FunctionalError(
        "change password command cep should have 2 passwords without spaces seperated by 1 space",
      );
    }

    const [newEncryptionPassword, newEncryptionPasswordConfirmation] =
      args.split(" ");

    if (newEncryptionPassword !== newEncryptionPasswordConfirmation) {
      throw new FunctionalError("passwords are not equal");
    }

    const exportResult = await this.exportBotQuery.execute();

    await this.encryptionPasswordService.changeEncryptionPassword(
      newEncryptionPassword,
    );

    const feedback =
      "The encryption password is changed successfully. An export is automatically created in case the password change failed because of an error. You can use this export to restore your data.";

    return {
      commandName: this.commandName,
      feedback,
      returnParams: exportResult.returnParams,
    };
  }

  // Can't test this in integration
  /* istanbul ignore next */
  public async getAutoCompleteKeywords(
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    commandInput: string,
  ): Promise<string[]> {
    return Promise.resolve([]);
  }
}
