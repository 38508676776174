import { FunctionalError } from "../domain/errors/FunctionalError";

export class EmailHelper {
  public static validateEmailFormat(email: string) {
    // https://stackoverflow.com/a/9204568

    // anystring@anystring.anystring without multiple @
    const regularExpression = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // NOSONAR
    const valid = regularExpression.test(email);

    if (!valid) {
      throw new FunctionalError(`${email} is not a valid e-mail adress`);
    }
  }
}
