import * as JSZip from "jszip";

import { TechnicalError } from "../domain/errors/TechnicalError";

export class ZipUtils {
  public static newZip(): JSZip {
    return new JSZip();
  }

  public static async loadAsync(zipData: Uint8Array): Promise<JSZip> {
    const jsZip = new JSZip();
    // SONAR: Make sure that expanding this archive file is safe here.
    return jsZip.loadAsync(zipData); // NOSONAR
  }

  public static async generateAsync(zip: JSZip): Promise<Uint8Array> {
    return zip.generateAsync({ type: "uint8array" });
  }

  public static async unzipFile(zip: JSZip, fileName: string): Promise<string> {
    const zipObject = zip.file(fileName); // ignore during integration
    /* istanbul ignore if */
    if (!zipObject) {
      throw new TechnicalError(`Can't find file ${fileName} in zip`);
    }
    return zipObject.async("text");
  }

  public static async forEach(
    zipFolder: JSZip,
    lambda: (filename: string) => Promise<void>,
  ): Promise<void> {
    const promises: Array<Promise<void>> = [];
    // _relativePath can't be camelCase
    zipFolder.forEach(async (_relativePath, file) => {
      promises.push(lambda(file.name));
    });
    await Promise.all(promises);
  }
}
