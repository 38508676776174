import { Component, SyntheticEvent } from "react";

// TODO: Bug. Tab is broken when changing passsword (moves to command window)

type EncryptionPasswordInputProps = {
  onPasswordSubmitted: (password: string) => Promise<void>;
};

type EncryptionPasswordInputState = {
  password: string;
};

class EncryptionPasswordInput extends Component<
  EncryptionPasswordInputProps,
  EncryptionPasswordInputState
> {
  constructor(props: EncryptionPasswordInputProps) {
    super(props);
    this.state = {
      password: "",
    };
  }

  private handleSubmitPassword = async (event: SyntheticEvent) => {
    event.preventDefault();
    const password = this.state.password;
    // set text field to empty string
    this.setState({
      password: "",
    });
    await this.props.onPasswordSubmitted(password);
  };

  private setPassword = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ password: event.currentTarget.value });
  };

  override render() {
    return (
      <form onSubmit={this.handleSubmitPassword}>
        decryption password:{" "}
        <input
          autoFocus
          type="password"
          value={this.state.password}
          onChange={this.setPassword}
        />
        <input type="submit" value="decrypt" />
      </form>
    );
  }
}

export default EncryptionPasswordInput;
