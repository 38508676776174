import { Validate } from "../validators/Validate";
import { DomainEventModel } from "./DomainEventModel";

export class CompleteProjectEventModel extends DomainEventModel {
  public __type: string = "CompleteProjectEventModel";
  public modelVersion = 2;
  public static eventNameConstant = "CompleteProjectEvent";

  public eventName = CompleteProjectEventModel.eventNameConstant;

  protected static override expectedProperties = [
    "modelVersion",
    "__type",
    "eventName",
    "projectUuid",
  ].concat(super.expectedProperties);

  protected validateExpectedProperties() {
    Validate.expectedProperties(
      this,
      CompleteProjectEventModel.expectedProperties,
    );
  }

  constructor(public projectUuid: string) {
    super();
  }

  public override validate() {
    this.validateExpectedProperties();
    Validate.Uuidv4(this.projectUuid);
    super.validate();
  }
}
