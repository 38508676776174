import { IFileHelper } from "./IFileHelper";

export class FileInMemoryHelper implements IFileHelper {
  private fileContents = "";
  private fileExists = false;

  public async appendFile(data: string | Uint8Array): Promise<void> {
    this.fileContents += data;
    this.fileExists = true;
  }

  // unused in integration but needed for interface IFileHelper
  /* istanbul ignore next */
  public async readFile(): Promise<string> {
    if (!this.fileExists) {
      throw new Error("FileInMemoryHelper: ENOENT");
    }
    return this.fileContents;
  }

  // unused in integration but needed for interface IFileHelper
  /* istanbul ignore next */
  public async saveTextFile(text: string): Promise<void> {
    this.fileContents = text;
    this.fileExists = true;
  }
}
