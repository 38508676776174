import { IModel } from "../../../domain/domainModels/IModel";
import { ModelCloner } from "../../../util/ModelCloner";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function addMissingTypeFields(model: any) {
  // Events
  if (model.eventName && model.modelVersion === 1) {
    model.modelVersion = 2;
    const guessedType = guessEventType(model);
    addMissingType(model, guessedType);

    // Taskmodels in events
    if (model.taskModel) {
      addMissingType(model.taskModel, "TaskModel");
      model.taskModel.modelVersion = 3;
    }
    // Projecmodels in events
    if (model.projectModel) {
      addMissingType(model.projectModel, "ProjectModel");
      model.projectModel.modelVersion = 3;
    }
  }
}

function addMissingType(model: IModel, type: string) {
  /* istanbul ignore else */ // Forgive if field is already there
  if (!ModelCloner.HasProp(model, "__type")) {
    model.__type = type;
  }
}

// Derive the type for backwards compatibility reasons
function guessEventType(model: IModel): string {
  // eventNames are named after their class but sometime don't end in Model in v1
  if ((model.eventName as string).endsWith("Model")) {
    return model.eventName;
  } else {
    return model.eventName + "Model";
  }
}
