import { TechnicalError } from "../domain/errors/TechnicalError";
import { IFileSystem } from "../domain/IFileSystem";

/* istanbul ignore next */
export class FileSystemUtils {
  /*eslint-disable-next-line @typescript-eslint/no-explicit-any */
  public static isNoSuchFileOrDirectoryError(error: any): boolean {
    // TODO: Do Integration test in azure
    return (
      error?.message?.includes("ENOENT") || // Not used during integration
      /* istanbul ignore next */ error.statusCode === 404
    );
  }

  public static validateIsNoSuchFileOrDirectoryError(error: Error): void {
    /* istanbul ignore if */ // Not used during integration
    if (!FileSystemUtils.isNoSuchFileOrDirectoryError(error)) {
      throw error;
    }
  }

  public static validatePath(path: string, dataFolder: string): void {
    /* istanbul ignore if */ // Does not happen during integration
    if (!path.startsWith(dataFolder) || !dataFolder) {
      throw new TechnicalError(
        `Path ${path} does not validate with dataFolder ${dataFolder}`,
      );
    }
  }

  public static getParentDirectory(path: string): string {
    const directories = path.split("/");
    directories.splice(-1, 1);
    return directories.join("/");
  }

  /* istanbul ignore next */ // TODO: Test
  public static async DoesDirectoryExist(
    fileSystem: IFileSystem,
    dir: string,
  ): Promise<boolean> {
    try {
      await fileSystem.readDir(dir);
    } catch (e) {
      return !FileSystemUtils.isNoSuchFileOrDirectoryError(e);
    }
    return true;
  }
}
