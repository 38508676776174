import { TextUtils } from "../../../util/TextUtils";
import { AutoCompleteService } from "../../AutoCompleteService";
import { ProjectService } from "../../ProjectService";
import { SmartGenerator } from "../../SmartCapture/SmartGenerator";
import { SmartParser } from "../../SmartCapture/SmartParser";
import { TaskService } from "../../TaskService";
import { IExecutableBotCommand } from "../IBotCommand";
import { IBotCommandResult } from "../IBotCommandResult";

export class UpdateProjectBotCommand implements IExecutableBotCommand {
  public readonly commandName = "up";
  public readonly description = "Update a project";
  public readonly smartButtonText = "Update project";
  public readonly argumentDescription = "<number> projectname <$project goal>";

  constructor(
    private readonly autoCompleteService: AutoCompleteService,
    private readonly projectService: ProjectService,
    private readonly taskService: TaskService,
    private readonly smartGenerator: SmartGenerator,
  ) {}

  public async execute(args: string): Promise<IBotCommandResult> {
    const [projectNumber, ...remainder] = args.split(" ");
    const smartCaptureString = remainder.join(" ");
    const projectDisplayId = parseInt(projectNumber, 10);

    let feedback = this.validArguments(projectDisplayId, smartCaptureString);
    if (!feedback) {
      feedback = await this.updateProject(projectDisplayId, smartCaptureString);
    }

    return {
      commandName: this.commandName,
      feedback,
    };
  }

  // Can't test this in integration
  /* istanbul ignore next */
  public async getAutoCompleteKeywords(
    commandInput: string,
  ): Promise<string[]> {
    const input = AutoCompleteService.addSpaceAfterNumberArgument(commandInput);

    const uncompletedProjects =
      await this.smartGenerator.generateAllUnCompletedProjects();
    const uncompletedProjectsWithCommand = uncompletedProjects.map(
      (p) => "up " + p,
    );
    const allMatchingProjects = uncompletedProjectsWithCommand.filter((i) =>
      TextUtils.startsWithIgnoreCase(i, input),
    );

    return allMatchingProjects.map((t) => t.slice(3)); // remove "ut "
  }

  public async updateProject(
    projectDisplayId: number,
    stuff: string,
  ): Promise<string> {
    // ! Add a transaction when available
    const smartParser = new SmartParser();
    const updatedProject = smartParser.parseProjectText(stuff);

    try {
      const existingProjectVM =
        await this.projectService.getProject(projectDisplayId);

      // TODO: Won't this lose the creation time? Updating and saving existingProjectVM seems to break things,
      //  as we would save DisplayID. Get that feature back?
      updatedProject.uuid = existingProjectVM.uuid;

      await this.projectService.updateProject(updatedProject);
      await this.taskService.updateProjectName(
        existingProjectVM.project,
        updatedProject.project,
      );

      return "1 project updated";
    } catch (error) {
      return "Error: " + (error as Error).message;
    }
  }

  private validArguments(
    projectDisplayId: number,
    smartCaptureString: string,
  ): string {
    if (!projectDisplayId) {
      return "Error: No valid project provided. You need to provide a project like this: up 1 project name";
    }
    if (!smartCaptureString) {
      return "Error: Could not update project with no data";
    }
    return "";
  }
}
