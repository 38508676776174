// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function migrateGoalsToOutcomes(event: any) {
  if (
    event.projectModel &&
    event.projectModel.goals &&
    !event.projectModel.outcomes
  ) {
    event.projectModel.outcomes = event.projectModel.goals;
    delete event.projectModel.goals;
    event.projectModel.modelVersion = 2;
  }
}
