export class FunctionalError extends Error {
  public static IsFunctionalError(error: unknown): boolean {
    return error instanceof FunctionalError;
  }

  public static toStringOrThrow(e: Error): string {
    /* istanbul ignore else */ // No unexpected errors in integrationtest possible
    if (FunctionalError.IsFunctionalError(e)) {
      return FunctionalError.toMessage(e);
    } else {
      // If failed to catch, throwing it higher.
      throw e;
    }
  }

  public static toMessage(e: FunctionalError): string {
    return "Error: " + e.message;
  }
}
