// Write Integrationtest after we went to production
import { IModel } from "../domain/domainModels/IModel";
import { DependencyInjectionUtils } from "./DependencyInjectionUtils";
import { HttpErrorHelper } from "./HttpErrorHelper";

/*eslint-disable @typescript-eslint/no-explicit-any */

// Type from lib.dom.d.ts fetch
export type fetchType = (
  input: RequestInfo,
  init?: RequestInit,
) => Promise<Response>;

/* istanbul ignore file */ // Not used during integration test when azure is turned off.

export class RestUtils {
  constructor(private readonly fetchFn: fetchType) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async get(url: string, headers?: HeadersInit): Promise<Response> {
    return await HttpErrorHelper.handleErrors(
      () =>
        this.fetchFn(url, {
          method: "GET",
          headers: headers,
        }),
      url,
      "GET",
      "",
      headers,
    );
  }

  public async post(
    url: string,
    data: IModel = {},
    headers?: HeadersInit,
  ): Promise<any> {
    const response = await HttpErrorHelper.handleErrors(
      () =>
        this.fetchFn(url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
        }),
      url,
      "POST",
      JSON.stringify(data),
      headers,
    );
    return response.json();
  }

  public async put(
    url: string,
    data: Record<string, unknown> = {},
    headers?: HeadersInit,
  ): Promise<any> {
    const response = await HttpErrorHelper.handleErrors(
      () =>
        this.fetchFn(url, {
          method: "PUT",
          headers: headers,
          body: JSON.stringify(data),
        }),
      url,
      "PUT",
      JSON.stringify(data),
      headers,
    );
    return response.json();
  }

  public async delete(url: string, headers?: HeadersInit): Promise<any> {
    const response = await HttpErrorHelper.handleErrors(
      () =>
        this.fetchFn(url, {
          headers: headers,
          method: "DELETE",
        }),
      url,
      "DELETE",
      "",
      headers,
    );
    return response.json(); // TODO: Why return .json everywhere? Could also be text or something else. And why for a delete?
  }

  public async head(url: string, headers?: HeadersInit): Promise<any> {
    const response = await HttpErrorHelper.handleErrors(
      () =>
        this.fetchFn(url, {
          headers: headers,
          method: "HEAD",
        }),
      url,
      "HEAD",
      "",
      headers,
    );
    return response;
  }
}
