import { AbstractRepository } from "brainsupporter-core/lib/repository/AbstractRepository";
import { DateTimeUtils } from "brainsupporter-core/lib/util/DateTimeUtils";
import { TypedJsonParser } from "brainsupporter-core/lib/util/TypedJsonParser";
import { IRepository } from "brainsupporter-core/lib//domain/IRepository";
import { timestamp } from "brainsupporter-core/lib/domain/Types";
import { IIdentifiable } from "brainsupporter-core/lib/domain/domainModels/IIdentifiable";
import { IValidate } from "brainsupporter-core/lib/domain/domainModels/IValidate";
import { DependencyInjectionUtils } from "brainsupporter-core/lib/util/DependencyInjectionUtils";

export class LocalStorageRepository<T extends IIdentifiable & IValidate>
  extends AbstractRepository<T>
  implements IRepository<T>
{
  constructor(private readonly localStoragePrefix: string) {
    super();

    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public getAll(): Promise<T[]> {
    const clonedModels = [];
    for (const jsonModel of Object.values(window.localStorage)) {
      let model: T;
      try {
        model = TypedJsonParser.parseJson(jsonModel) as T;
        model.validate();
      } catch (error) {
        // skip without error
        continue;
      }
      clonedModels.push(model);
    }

    return Promise.resolve(clonedModels);
  }

  public async save(model: T): Promise<timestamp> {
    const value = TypedJsonParser.toJson(model); // use indexedDb if we run into limits for localStorage (5mb?)
    window.localStorage.setItem(this.getKey(model.uuid), value);
    return Promise.resolve(DateTimeUtils.zeroTimestamp);
  }

  public async deleteAll(): Promise<timestamp> {
    const allKeys = Object.keys(localStorage);

    const keysFromThisRepository = allKeys.filter((value) => {
      return value.startsWith(this.localStoragePrefix);
    });

    keysFromThisRepository.forEach((value) => {
      window.localStorage.removeItem(value);
    });

    return Promise.resolve(DateTimeUtils.zeroTimestamp);
  }

  // only implemented for interface
  /* istanbul ignore next */
  public async getTimestamp(): Promise<timestamp> {
    return Promise.resolve(DateTimeUtils.zeroTimestamp);
  }

  // only implemented for interface
  /* istanbul ignore next */
  public async delete(uuid: string): Promise<timestamp> {
    window.localStorage.removeItem(this.getKey(uuid));
    return Promise.resolve(DateTimeUtils.zeroTimestamp);
  }

  private getKey(uuid: string): string {
    return this.localStoragePrefix + "-" + uuid;
  }
}
