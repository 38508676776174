import { Component } from "react";
import "./App.css";
import Webconsole from "./components/Webconsole/Webconsole";
import ErrorNotification from "./components/ErrorNotification/ErrorNotification";
import EncryptionPasswordInput from "./components/EncryptionPasswordInput/EncryptionPasswordInput";
import { WebCompositionRoot } from "./WebCompositionRoot";
import Loader from "./components/Loader/Loader";
import NewEncryptionPasswordInput from "./components/NewEncryptionPasswordInput/NewEncryptionPasswordInput";
import { EmailHelper } from "brainsupporter-core/lib/util/EmailHelper";
import Footer from "./components/Footer/Footer";
import { VersionUtils } from "brainsupporter-core/lib/util/VersionUtils";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import {
  reactPlugin,
  initializeTelemetry,
} from "./applicationInsights/ApplicationInsightsService";
import { EncryptionService } from "brainsupporter-core/lib/domain/EncryptionService";

// TODO: Bug: With a window open and a new version installed it could give an empty screen. In devtools it can be seen
// the js of the previous version was loaded (with a different hash.) example: static/js/main.093a2e92.js
// Not exactly sure how to reproduce. Solution update via progressive web app?

type AppState = {
  errorMessages: string[];
  email: string;
  decrypted: boolean;
  loading: number; // nr of loading tasks running
  userExists: boolean;
  loggedIn: boolean | null; // loggedIn null means we don't know yet
};

class App extends Component<unknown, AppState> {
  private root: WebCompositionRoot;
  private static alreadyMounted = false;

  private static authenticatedUserUrl = "api/authenticatedUser";
  private static logoutUrl = "api/logout";

  constructor(props: unknown) {
    super(props);
    this.root = new WebCompositionRoot();
    this.state = {
      errorMessages: [],
      email: "",
      decrypted: false,
      loading: 0,
      userExists: false,
      loggedIn: null,
    };
  }

  private visibilityChangeListener = async (): Promise<void> => {
    // TODO: Now we have a longer session we don't need to check the server every time.
    // Instead do it once every day (so remember the time last checked).
    if (!document.hidden) {
      await this.checkAuthentication();
      Webconsole.focusToCommand();
    }
  };

  override async componentDidMount() {
    // TODO: v1.0.3: this should probably happen somewhere else, to prevent it from
    // happening every time the component is mounted. Maybe it should be an application state?
    // https://reactjs.org/blog/2022/03/29/react-v18.html#new-strict-mode-behaviors
    // https://reactjs.org/docs/strict-mode.html#ensuring-reusable-state
    if (!App.alreadyMounted) {
      // Strict mode calls componentDidMount twice, so we check if it's already run
      App.alreadyMounted = true;
      await this.executeAction(async () => {
        document.addEventListener("visibilitychange", () =>
          this.visibilityChangeListener(),
        );
        Webconsole.focusToCommand();

        const email = await this.checkAuthentication();
        const password =
          await this.root.EncryptionPasswordService.getEncryptionPassword(
            email,
          );

        if (!(await this.checkLogin(email, password))) {
          return;
        }

        const accountHash = EncryptionService.computeMD5(email);
        initializeTelemetry(accountHash);

        // Password is in local storage
        await this.root.EventStore.replayEventsAndSubscribe();

        this.setState({
          decrypted: true,
          userExists: true,
        });
      });

      this.setEnviromentColors();
    }
  }

  override componentWillUnmount(): void {
    document.removeEventListener(
      "visibilitychange",
      this.visibilityChangeListener,
    );
  }

  private checkLogin = async (
    email: string,
    password: string | null,
  ): Promise<boolean> => {
    const result = await this.executeAction<boolean>(async () => {
      if (email) {
        EmailHelper.validateEmailFormat(email);
      } else {
        return false;
      }

      const encryptionPasswordIsCached =
        this.root.EncryptionPasswordService.isEncryptionPasswordCached(email);
      const userExists = await this.root.UserService.userExists();

      if (!userExists || !encryptionPasswordIsCached || password === null) {
        this.setState({
          decrypted: false,
          userExists: userExists,
          email: email,
          loggedIn: !!email,
        });
        return false;
      }

      try {
        await this.root.EncryptionPasswordService.verifyExistingEncryptionPassword(
          email,
          password as string,
        );
      } catch (e: unknown) {
        const error = e as Error;
        if (error.message === "Encryption password is incorrect.") {
          this.setState({
            decrypted: false,
            userExists: true,
          });
          return false;
        } else {
          throw error;
        }
      }

      return true;
    });

    if (result === undefined) {
      return false;
    }
    return result;
  };

  private showError = async (errorMessage: string) => {
    // add error
    this.setState((oldState) => ({
      errorMessages: [...oldState.errorMessages, errorMessage],
    }));

    // wait 20 seconds
    await new Promise((resolve) => setTimeout(resolve, 20 * 1000));

    // remove error
    this.setState((oldState) => ({
      errorMessages: (() => this.removeErrorMessage(oldState, 0))(),
    }));
  };

  private async checkAuthentication(): Promise<string> {
    let email = "";
    try {
      const authenticatedUserResponse = await this.root.RestUtils.get(
        App.authenticatedUserUrl,
      );
      if (authenticatedUserResponse.status === 200) {
        // user is logged in
        email = await authenticatedUserResponse.text();
      }
    } catch (e: unknown) {
      // not logged in
    }

    if (!email) {
      email = await this.getEmailFromOAuth();
      if (email) {
        // New user
        this.setState({
          userExists: false, // TODO: We set userExists manually and by this.root.UserService.userExists. Can we simplify?
        });
      }
    }

    this.root.ConfigurationManager.setEmail(email);
    this.setState({
      loggedIn: email !== "",
      email: email,
    });

    return email;
  }

  private async getEmailFromOAuth(): Promise<string> {
    const response = await fetch("/.auth/me");
    if (response.status !== 200) {
      // not logged in
      return "";
    }

    const payload = await response.json();
    const { clientPrincipal } = payload;
    let email;
    if (clientPrincipal !== null) {
      email = clientPrincipal.userDetails;
    } else {
      email = "";
    }

    return email;
  }

  private removeErrorMessage(oldState: AppState, index: number): string[] {
    const newState = oldState.errorMessages.slice();
    newState.splice(index, 1);
    return newState;
  }

  private encryptionPasswordSubmitted = async (
    password: string,
  ): Promise<void> => {
    await this.executeAction(async () => {
      this.root.EncryptionKeySecureCache.clearCache();
      await this.root.EncryptionPasswordService.verifyExistingEncryptionPassword(
        this.state.email,
        password,
      );

      // Password is not in local storage, but entered by the user
      await this.root.EventStore.replayEventsAndSubscribe();

      this.setState({
        decrypted: true,
      });
    });
  };

  private newUserEncryptionPasswordSubmitted = async (
    password: string,
  ): Promise<void> => {
    await this.executeAction(async () => {
      await this.root.UserService.createNewUser(this.state.email, password);

      this.root.EventStore.startSavingEvents();

      this.setState({
        decrypted: true,
      });
    });
  };

  // Do action, show errors and set spinner
  private async executeAction<T>(
    action: () => Promise<T>,
  ): Promise<T | undefined> {
    this.setLoading(true);

    try {
      return await action();
    } catch (e: unknown) {
      const error = e as Error;
      await this.showError(error.message);
    } finally {
      this.setLoading(false);
    }
  }

  private setLoading = (loading: boolean) => {
    const loadingDelta = loading ? 1 : -1;

    this.setState((prevState) => ({
      loading: prevState.loading - loadingDelta,
    }));
  };

  private IsLoggedIn() {
    return this.state.email;
  }

  private async logout() {
    await this.root.RestUtils.post(App.logoutUrl);
    window.location.href = "/";
  }

  private setEnviromentColors() {
    if (!VersionUtils.isProduction()) {
      let color: string;
      if (VersionUtils.isCanary()) {
        color = "lightyellow";
      } else {
        color = "lightblue";
      }

      document.getElementById("topheader")!.style.backgroundColor = color;
      document.getElementById("topdivider")!.style.backgroundColor = color;
    }
  }

  override render() {
    let mainComponent;

    if (this.state.loggedIn === false) {
      mainComponent = "Not logged in";
    } else if (this.state.decrypted) {
      mainComponent = (
        <Webconsole
          errorMessage={this.showError}
          root={this.root}
          setLoading={this.setLoading}
        />
      );
    } else if (
      this.IsLoggedIn() &&
      this.state.userExists &&
      !this.state.loading
    ) {
      mainComponent = (
        <EncryptionPasswordInput
          onPasswordSubmitted={this.encryptionPasswordSubmitted}
        />
      );
    } else if (this.IsLoggedIn() && !this.state.loading) {
      mainComponent = (
        <NewEncryptionPasswordInput
          onPasswordSubmitted={this.newUserEncryptionPasswordSubmitted}
        />
      );
    } else {
      mainComponent = null;
    }

    let loginLogout = null;
    if (this.state.loggedIn === null) {
      loginLogout = "";
    } else if (this.state.loggedIn) {
      loginLogout = (
        <a
          href="#"
          onClick={async () => {
            await this.logout();
          }}
        >
          Log out
        </a>
      );
    } else {
      loginLogout = <a href="/.auth/login/google">Login with Google</a>;
    }

    return (
      <div className="App">
        {/* TODO: Skip AppInsightsContext when turned of. */}
        <AppInsightsContext.Provider value={reactPlugin}>
          <nav className="menu" id="topheader">
            <div>{loginLogout}</div>
            <div className="user">
              <a>{this.state.email}</a>
            </div>
            {/* TODO: User should be a span and after nav keep all on 1 line */}
          </nav>
          <div id="topdivider">
            <hr />
          </div>
          <Loader loading={!!this.state.loading} />
          <ErrorNotification
            errorMessages={this.state.errorMessages}
            onChange={(errorMessages: string[]) =>
              this.setState({ errorMessages })
            }
          />
          {mainComponent}
          <Footer />
        </AppInsightsContext.Provider>
      </div>
    );
  }
}

export default App;
