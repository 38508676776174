// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function renameUrlsToLinksInTaskModel(event: any) {
  if (
    event.taskModel &&
    event.taskModel.modelVersion == 1 &&
    event.taskModel.urls
  ) {
    event.taskModel.links = event.taskModel.urls;
    delete event.taskModel.urls;
    event.taskModel.modelVersion = 2;
  }
}
