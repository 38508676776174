/* eslint-disable @typescript-eslint/no-explicit-any */

export class ArrayUtils {
  public static distinct(list: any[]): any[] {
    // https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
    return [...new Set(list)];
  }

  public static flatten(arrayOfArrays: any[][]): any[] {
    // https://stackoverflow.com/questions/10865025/merge-flatten-an-array-of-arrays-in-javascript
    return ([] as any[]).concat(...arrayOfArrays);
  }

  public static mergeDistinct(arrayOfArrays: any[][]): any[] {
    return ArrayUtils.distinct(ArrayUtils.flatten(arrayOfArrays));
  }

  public static difference<T>(set: T[], substract: T[]): T[] {
    return set.filter((x) => !substract.includes(x));
  }
}
