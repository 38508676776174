import { UpdateProjectEventModel } from "../../../domain/events/UpdateProjectEventModel";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeNullFromOutcomes(event: any) {
  if (
    event.eventName &&
    event.eventName === UpdateProjectEventModel.eventNameConstant
  ) {
    const project = event.projectModel;
    const newOutcomes = project.outcomes.filter(
      (outcome: unknown) => outcome !== null,
    );
    project.outcomes = newOutcomes;
  }
}
