import { Component } from "react";
import "./Footer.css";
import Version from "../Version/Version";

class Footer extends Component<unknown, unknown> {
  override render() {
    const year = new Date().getFullYear();
    return (
      <footer>
        <hr></hr>
        <ul>
          <li className="version">
            <Version />
          </li>
          <li>
            <a href="/release-notes.html">Release notes</a>
          </li>
          <li>
            <a href="/privacy-policy.html">Privacy policy</a>
          </li>
          <li>&copy; {year} Brainsupporter</li>
        </ul>
      </footer>
    );
  }
}

export default Footer;
