import { IEncryptedModel } from "../domainModels/IEncryptedModel";
import { Validate } from "../validators/Validate";

/* istanbul ignore next */ // Only used serverside behind api which doesn't cover during integration
export class EncryptedDomainEventModel implements IEncryptedModel {
  public uuid = "";
  public modelVersion = "";

  public eventDateTime = "";
  public sequenceNumber = "";

  public eventName = "";

  public validate() {
    EncryptedDomainEventModel.validateModel(this);
  }

  public static validateModel(model: EncryptedDomainEventModel) {
    // Carefull, as encrypted events are immutable and don't migrate we can't just validate the newest version
    Validate.Uuidv4(model.uuid);
    Validate.stringNotEmpty(model.modelVersion);
    Validate.stringNotEmpty(model.eventDateTime);
    Validate.stringNotEmpty(model.sequenceNumber);
    Validate.stringNotEmpty(model.eventName);
  }
}
