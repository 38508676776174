import { DomainEventModel } from "../../../domain/events/DomainEventModel";
import { ModelCloner } from "../../../util/ModelCloner";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertTypes(event: any): DomainEventModel {
  if (
    ModelCloner.HasProp(event, "__type") &&
    event.constructor.name != event.__type
  ) {
    return ModelCloner.cloneToType(event) as DomainEventModel;
  } else {
    return event;
  }
}
