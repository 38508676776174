import { IModel } from "../domain/domainModels/IModel";
import { ModelCloner } from "./ModelCloner";

export class TypedJsonParser {
  public static toJson(model: IModel): string {
    const json = JSON.stringify(model);
    return json;
  }

  public static parseJson<T extends IModel>(json: string): T {
    const parsed = JSON.parse(json);
    return ModelCloner.cloneToType(parsed) as T;
  }
}
