import { ConfigurationManager } from "../../config/ConfigurationManager";
import { LogService } from "../../logging/LogService";
import { DependencyInjectionUtils } from "../../util/DependencyInjectionUtils";
import { TextUtils } from "../../util/TextUtils";
import { FunctionalError } from "../errors/FunctionalError";
import { BotEventModel } from "./BotEventModel";
import { AddBotCommand } from "./commands/AddBotCommand";
import { ChangeEncryptionPasswordBotCommand } from "./commands/ChangeEncryptionPasswordBotCommand";
import { CompleteProjectBotCommand } from "./commands/CompleteProjectBotCommand";
import { CompleteTaskBotCommand } from "./commands/CompleteTaskBotCommand";
import { ImportBotCommand } from "./commands/ImportBotCommand";
import { MaybeBotCommand } from "./commands/MaybeBotCommand";
import { SetContextBotCommand } from "./commands/SetContextBotCommand";
import { UpdateProjectBotCommand } from "./commands/UpdateProjectBotCommand";
import { UpdateTaskBotCommand } from "./commands/UpdateTaskBotCommand";
import { IExecutableBotCommand } from "./IBotCommand";
import { IBotCommandResult } from "./IBotCommandResult";
import { ExportBotQuery } from "./queries/ExportBotQuery";
import { HelpBotQuery } from "./queries/HelpBotQuery";
import { ListContextBotQuery } from "./queries/ListContextBotQuery";
import { ListMaybeBotQuery } from "./queries/ListMaybeBotQuery";
import { ListProjectsBotQuery } from "./queries/ListProjectsBotQuery";
import { ListTasksBotQuery } from "./queries/ListTasksBotQuery";
import { OpenLinksBotQuery } from "./queries/OpenLinksBotQuery";
import { VersionBotQuery } from "./queries/VersionBotQuery";

export class BotService {
  constructor(
    private readonly addBotCommand: AddBotCommand,
    private readonly changeEncryptionPasswordBotCommand: ChangeEncryptionPasswordBotCommand,
    private readonly completeProjectBotCommand: CompleteProjectBotCommand,
    private readonly completeTaskBotCommand: CompleteTaskBotCommand,
    private readonly maybeBotCommand: MaybeBotCommand,
    private readonly exportBotQuery: ExportBotQuery,
    private readonly importBotCommand: ImportBotCommand,
    private readonly listContextBotQuery: ListContextBotQuery,
    private readonly listMaybeBotQuery: ListMaybeBotQuery,
    private readonly listProjectsBotQuery: ListProjectsBotQuery,
    private readonly listTaskBotCommand: ListTasksBotQuery,
    private readonly openLinksBotQuery: OpenLinksBotQuery,
    private readonly setContextBotCommand: SetContextBotCommand,
    private readonly updateProjectBotCommand: UpdateProjectBotCommand,
    private readonly updateTaskBotCommand: UpdateTaskBotCommand,
    private readonly versionBotQuery: VersionBotQuery,
    private readonly helpBotQuery: HelpBotQuery,

    private readonly configurationManager: ConfigurationManager,
    private readonly logService: LogService,
  ) {
    helpBotQuery.injectBotService(this); // Break circular dependency

    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async sendText(text: string): Promise<IBotCommandResult> {
    return this.sendTextWithAttachment(text);
  }

  public async sendTextWithAttachment(
    text: string,
    attachment?: Uint8Array,
  ): Promise<IBotCommandResult> {
    const botEventModel = this.createBotEventModel(text, attachment);

    return this.executeBotEvent(botEventModel);
  }

  public createBotEventModel(
    text: string,
    attachment?: Uint8Array,
  ): BotEventModel {
    const botEventModel = new BotEventModel();
    const textParts = text.split(" ");
    botEventModel.command = textParts[0];
    botEventModel.argument = textParts.splice(1).join(" ");
    botEventModel.attachment = attachment;

    return botEventModel;
  }

  public async executeBotEvent(
    botEventModel: BotEventModel,
  ): Promise<IBotCommandResult> {
    let result: IBotCommandResult;

    try {
      let botCommand = this.getBotCommand(botEventModel.command);
      let argument = botEventModel.argument;
      if (botCommand === undefined) {
        // no command found, so default to add command
        botCommand = this.addBotCommand;
        argument = `${botEventModel.command} ${botEventModel.argument}`;
      }
      result = botEventModel.attachment
        ? await botCommand.execute(argument, botEventModel.attachment)
        : await botCommand.execute(argument);
      return result;
    } catch (error) {
      if (FunctionalError.IsFunctionalError(error)) {
        return {
          commandName: botEventModel.command,
          feedback: FunctionalError.toMessage(error as Error),
        };
      } else {
        await this.logService.logError(error as Error);

        return {
          commandName: botEventModel.command,
          feedback: "A technical error occurred",
        };
      }
    }
  }

  public getBotCommand(
    commandInput: string,
  ): IExecutableBotCommand | undefined {
    for (const botCommand of this.listExecutableCommands()) {
      if (TextUtils.compareIgnoreCase(commandInput, botCommand.commandName)) {
        return botCommand;
      }
    }
  }

  public async getAutocompleteKeywords(
    commandInput: string,
  ): Promise<string[]> {
    const commands = this.listExecutableCommands();
    const commandKeyWords = commands.map((c) => c.commandName);

    const commandParts = commandInput.split(" ");
    const commandPart = commandParts[0].toLowerCase();
    // Lowercase the command
    commandInput = commandPart;
    if (commandParts.length > 1) {
      commandInput += " " + commandParts.slice(1).join(" ");
    }

    if (commandInput === "") {
      // Return all command when input is empty
      return commandKeyWords;
    }

    // If no space it might be a partial command
    if (!commandInput.includes(" ")) {
      // Unless it is a full command
      if (
        !commandKeyWords.some((c) => c === commandInput.toLocaleLowerCase())
      ) {
        // Complete partial commands
        return commandKeyWords.filter((c) =>
          TextUtils.startsWithIgnoreCase(c, commandInput),
        );
      }
    }

    let command = this.getBotCommand(commandPart);
    if (command === undefined) {
      command = this.addBotCommand;
    }
    return command.getAutoCompleteKeywords(commandInput);
  }

  public listExecutableCommands(): IExecutableBotCommand[] {
    // In order of help command
    const commands = [
      this.helpBotQuery,

      this.addBotCommand,
      this.listTaskBotCommand,
      this.completeTaskBotCommand,
      this.updateTaskBotCommand,

      this.openLinksBotQuery,

      this.completeProjectBotCommand,
      this.updateProjectBotCommand,
      this.listProjectsBotQuery,

      this.setContextBotCommand,
      this.listContextBotQuery,

      this.maybeBotCommand,
      this.listMaybeBotQuery,

      this.exportBotQuery,
      this.importBotCommand,

      this.changeEncryptionPasswordBotCommand,

      this.versionBotQuery,
    ];
    const disabledCommands = this.configurationManager.disabledCommands();

    return commands.filter((c) => {
      return !disabledCommands.includes(c.commandName);
    });
  }
}
