import { IIdentifiable } from "../domain/domainModels/IIdentifiable";
import { TechnicalError } from "../domain/errors/TechnicalError";
import { IRepository } from "../domain/IRepository";
import { timestamp } from "../domain/Types";
import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";

// TODO: Document SingleRepository in puml diagram
export class SingleRepository<T extends IIdentifiable> {
  private uuid?: string = undefined;

  constructor(private repository: IRepository<T>) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async save(model: T): Promise<timestamp> {
    await this.guardSameUuid(model.uuid);
    return this.repository.save(model);
  }

  public async get(): Promise<T> {
    const model = await this.tryGet();

    /* istanbul ignore if */ // Error can't happen in integration
    if (!model) {
      throw new TechnicalError("SingleRepository is empty using get()");
    }
    return model;
  }

  public async tryGet(): Promise<T | undefined> {
    const models = await this.repository.getAll();

    /* istanbul ignore if */ // Error can't happen in integration
    if (models.length > 1) {
      throw new TechnicalError("SingleRepository contains multiple models");
    } else if (models.length === 1) {
      return models[0];
    } else {
      return undefined;
    }
  }

  private async guardSameUuid(modelUuid: string) {
    if (!this.uuid) {
      const model = await this.tryGet();

      if (!model) {
        return;
      }

      this.uuid = model.uuid;
    }
    /* istanbul ignore if */ // Error can't happen in integration
    if (this.uuid !== modelUuid) {
      throw new TechnicalError("SingleRepository can't save a second model");
    }
  }
}
