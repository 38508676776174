import { DependencyInjectionUtils } from "../../../util/DependencyInjectionUtils";
import { FunctionalError } from "../../errors/FunctionalError";
import { TaskService } from "../../TaskService";
import { IExecutableBotCommand } from "../IBotCommand";
import { IBotCommandResult } from "../IBotCommandResult";

// TODO: Bug: ct 1188huh completes task 1188 but should return an error

export class CompleteTaskBotCommand implements IExecutableBotCommand {
  public readonly commandName = "ct";
  public readonly description = "Complete task";
  public readonly smartButtonText = "Complete task";
  public readonly argumentDescription = "tasknumber";

  constructor(private readonly taskService: TaskService) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async execute(taskNumberString: string): Promise<IBotCommandResult> {
    const taskNumber = parseInt(taskNumberString, 10);
    const feedback = await this.completeTask(taskNumber);
    return {
      commandName: this.commandName,
      feedback,
    };
  }

  public async completeTask(index: number): Promise<string> {
    try {
      await this.taskService.completeTask(index);
      const completedTask = await this.taskService.getTask(index);
      let feedback: string = "task completed 😁 " + completedTask.task;

      for (const project of completedTask.projects) {
        if (
          (await this.taskService.getUncompletedTasks(project)).length === 0
        ) {
          feedback +=
            "\nProject: '" + project + "' has completed all next actions.\n";
        }
      }

      return feedback;
    } catch (error) {
      return FunctionalError.toStringOrThrow(error as Error);
    }
  }

  // Can't test this in integration
  /* istanbul ignore next */
  public async getAutoCompleteKeywords(
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    commandInput: string,
  ): Promise<string[]> {
    return Promise.resolve([]);
  }
}
