import { ModelCloner } from "../../../util/ModelCloner";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function renameLaterToMaybeInTaskModel(event: any) {
  if (
    event.taskModel &&
    event.taskModel.modelVersion <= 2 &&
    ModelCloner.HasProp(event.taskModel, "later")
  ) {
    event.taskModel.maybe = event.taskModel.later;
    delete event.taskModel.later;
    event.taskModel.modelVersion = 3;
  }
}
