import {
  ApplicationInsights,
  ITelemetryItem,
} from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { VersionUtils } from "brainsupporter-core/lib/util/VersionUtils";

// Example from https://github.com/microsoft/applicationinsights-react-js/blob/main/sample/applicationinsights-react-sample/src/ApplicationInsightsService.tsx

let connectionString = "";
if (VersionUtils.isDev()) {
  connectionString =
    "InstrumentationKey=c3579b62-9d22-4f11-897c-c22e308d2452;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=c77edd7b-142a-49a7-8e37-3e2171039fb9";
}

if (VersionUtils.isCanary()) {
  connectionString =
    "InstrumentationKey=b108dfcd-1c75-47a2-bfdf-d4e3b4ba4d0d;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=52a57e89-cd70-4bdb-9721-738a9eae6635";
}
const disableTelemetry = !connectionString;

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: connectionString,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableAjaxPerfTracking: true,

    disableTelemetry: disableTelemetry,
  },
});

if (!disableTelemetry) {
  appInsights.loadAppInsights();
}

function initializeTelemetry(accountFolder: string) {
  appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
    env.data = env.data || {};
    env.data["full-version"] = VersionUtils.getFullVersion();
    env.data["accountFolder"] = accountFolder;
  });
}

export { reactPlugin, appInsights, initializeTelemetry };
