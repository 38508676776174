import { DependencyInjectionUtils } from "../../../util/DependencyInjectionUtils";
import { ContextService } from "../../ContextService";
import { IExecutableBotCommand } from "../IBotCommand";
import { IBotCommandResult } from "../IBotCommandResult";

// TODO: Is this usefull for the user as a query? If not, should we hide it from the help or place it in an advanced section?
export class ListContextBotQuery implements IExecutableBotCommand {
  public static staticCommandName = "lc";
  public readonly commandName = ListContextBotQuery.staticCommandName;
  public readonly description = "List contexts";
  public readonly smartButtonText = "List contexts";

  constructor(private readonly contextService: ContextService) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async execute(): Promise<IBotCommandResult> {
    const contexts =
      await this.contextService.getContextsFromRecentOrActiveTasks();
    const feedback = contexts.map((c) => "@" + c).join("\n");

    return {
      commandName: this.commandName,
      feedback,
    };
  }

  // Can't test this in integration
  /* istanbul ignore next */
  public async getAutoCompleteKeywords(
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    commandInput: string,
  ): Promise<string[]> {
    return Promise.resolve([]);
  }
}
