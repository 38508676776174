import { Validate } from "../validators/Validate";
import { DomainEventModel } from "./DomainEventModel";

export class TaskMaybeEventModel extends DomainEventModel {
  public __type: string = "TaskMaybeEventModel";
  public modelVersion = 2;
  public static eventNameConstant = "TaskMaybeEventModel";

  public eventName = TaskMaybeEventModel.eventNameConstant;

  protected static override expectedProperties = [
    "modelVersion",
    "__type",
    "eventName",
    "taskUuid",
    "maybeProperty",
  ].concat(super.expectedProperties);

  protected validateExpectedProperties() {
    Validate.expectedProperties(this, TaskMaybeEventModel.expectedProperties);
  }

  constructor(
    public taskUuid: string,
    public maybeProperty: boolean,
  ) {
    super();
  }

  public override validate() {
    this.validateExpectedProperties();
    Validate.Uuidv4(this.taskUuid);
    Validate.boolean(this.maybeProperty);
    super.validate();
  }
}
