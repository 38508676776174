import * as polly from "polly-js";

import { LogService } from "../logging/LogService";
import { DependencyInjectionUtils } from "./DependencyInjectionUtils";
import { ModelCloner } from "./ModelCloner";

/* eslint-disable @typescript-eslint/no-explicit-any */

// Unittest in ResilientFileSystem
/* istanbul ignore next */ // can't test retry in integration tests
export class RetryHelper {
  constructor(
    private readonly retryObject: any,
    private readonly delays: number[],
    private readonly logService: LogService,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async Retry<T>(
    retryFunctionName: string,
    params: any[],
    isRetryableError?: (err: any) => boolean,
  ): Promise<T> {
    // polly mutates the state delays, eating up the array each retry, so we clone it in order to re-use it in the app.
    const clonedDelays = ModelCloner.clone(this.delays);

    let pol = polly();

    /* istanbul ignore else */ //  No test for no isRetryableError
    if (isRetryableError) {
      pol = pol.handle(isRetryableError);
    }

    return pol
      .logger(async (err: Error) => {
        // Called every error for retry
        await this.logService.logWarning(err);
        await this.logService.logWarningMessage(
          `Retrying: ${retryFunctionName}(${params.join(", ")})`,
        );
      })
      .waitAndRetry(clonedDelays)
      .executeForPromise(async () => {
        return await this.retryObject[retryFunctionName](...params);
      });
  }
}
