import { DependencyInjectionUtils } from "../../../util/DependencyInjectionUtils";
import { FunctionalError } from "../../errors/FunctionalError";
import { ProjectService } from "../../ProjectService";
import { IExecutableBotCommand } from "../IBotCommand";
import { IBotCommandResult } from "../IBotCommandResult";

export class CompleteProjectBotCommand implements IExecutableBotCommand {
  public readonly commandName = "cp";
  public readonly description =
    "Complete a project and all its uncompleted tasks";
  public readonly smartButtonText = "Complete project";
  public readonly argumentDescription = "projectnumber";

  constructor(private readonly projectService: ProjectService) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async execute(
    projectNumberString: string,
  ): Promise<IBotCommandResult> {
    const projectNumber = parseInt(projectNumberString, 10);
    const feedback = await this.completeProject(projectNumber);
    return {
      commandName: this.commandName,
      feedback,
    };
  }

  public async completeProject(index: number): Promise<string> {
    try {
      const completeProjectEventModel =
        await this.projectService.completeProject(index);
      let feedback =
        "Project completed 🎉 " + completeProjectEventModel.project.project;
      for (const task of completeProjectEventModel.tasksCompleted) {
        feedback += "\nTask in project set to completed: " + task.task;
      }
      for (const task of completeProjectEventModel.tasksNotCompleted) {
        feedback += "\nTask in project not completed: " + task.task;
      }
      return feedback;
    } catch (error) {
      return FunctionalError.toStringOrThrow(error as Error);
    }
  }

  // Can't test this in integration
  /* istanbul ignore next */
  public async getAutoCompleteKeywords(
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    commandInput: string,
  ): Promise<string[]> {
    return Promise.resolve([]);
  }
}
