import { ProjectModel } from "../domain/domainModels/ProjectModel";
import { IRepository } from "../domain/IRepository";
import { timestamp } from "../domain/Types";
import { DateTimeUtils } from "../util/DateTimeUtils";
import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { FileSystemUtils } from "../util/FileSystemUtils";
import { IFileHelper } from "../util/IFileHelper";
import { TextUtils } from "../util/TextUtils";
import { AbstractRepository } from "./AbstractRepository";

export class ProjectTxtRepository
  extends AbstractRepository<ProjectModel>
  implements IRepository<ProjectModel>
{
  constructor(private readonly fileHelper: IFileHelper) {
    super();

    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  // Not used in integration as we only use this for import export
  /* istanbul ignore next */
  public async save(projectModel: ProjectModel): Promise<timestamp> {
    const projectModels = await this.getAll();

    const existingProjectIndex = this.findProjectIndex(
      projectModels,
      projectModel.uuid,
    );

    if (existingProjectIndex === -1) {
      await this.saveProjectToFile(projectModel);
    } else {
      projectModels[existingProjectIndex] = projectModel;

      await this.saveAllProjects(projectModels);
    }
    return DateTimeUtils.zeroTimestamp; // No timestamp implemented
  }

  public async getAll(): Promise<ProjectModel[]> {
    const projectLines = await this.getProjectLines();

    return projectLines
      .filter((l) => {
        return l !== "";
      })
      .map((pl) => {
        return this.parseProjectLine(pl);
      });
  }

  // Not used in integration as we only use this for import export
  /* istanbul ignore next */
  public async deleteAll(): Promise<timestamp> {
    await this.fileHelper.saveTextFile("");
    return DateTimeUtils.zeroTimestamp; // No timestamp implemented
  }

  // only implemented for interface
  /* istanbul ignore next */
  public async getTimestamp(): Promise<timestamp> {
    throw new Error("Method not implemented.");
  }

  // hard to test during integration
  /* istanbul ignore next */
  public async delete(uuid: string): Promise<timestamp> {
    const projectModels = await this.getAll();
    const projectIndex = this.findProjectIndex(projectModels, uuid);
    projectModels.splice(projectIndex, 1);
    await this.saveAllProjects(projectModels);
    return DateTimeUtils.zeroTimestamp; // No timestamp implemented
  }

  // Not used in integration as we only use this for import export
  /* istanbul ignore next */
  private findProjectIndex(projectModels: ProjectModel[], uuid: string) {
    return projectModels.findIndex((pm: ProjectModel) => {
      return pm.uuid === uuid;
    });
  }

  // Not used in integration as we only use this for import export
  /* istanbul ignore next */
  private async getProjectLines(): Promise<string[]> {
    try {
      const projectContent = await this.fileHelper.readFile();
      return projectContent.split(/\r?\n/);
    } catch (error) {
      FileSystemUtils.validateIsNoSuchFileOrDirectoryError(error as Error);
      return [];
    }
  }

  private parseProjectLine(projectLine: string): ProjectModel {
    const completed =
      projectLine.startsWith("x ") || projectLine.startsWith("X ");
    let remainder = projectLine;

    if (completed) {
      remainder = projectLine.substring(2);
    }

    const allTokens = /(\$)|\suuid|\screationDateTime:|\scompletionDateTime:/;
    const project = TextUtils.extractTextFragmentUntilAnyToken(
      remainder,
      allTokens,
    );
    const outcomes = TextUtils.extractMultipleTextFragmentsWithoutTheirTokens(
      remainder,
      /\$/,
      allTokens,
    );
    const uuidField = TextUtils.extractSingleTextFragmentWithoutItsToken(
      remainder,
      /\suuid:/,
      allTokens,
    );
    const creationDateTimeText =
      TextUtils.extractSingleTextFragmentWithoutItsToken(
        remainder,
        /\screationDateTime:/,
        allTokens,
      );
    const completionDateTimeText =
      TextUtils.extractSingleTextFragmentWithoutItsToken(
        remainder,
        /\scompletionDateTime:/,
        allTokens,
      );

    const projectModel = new ProjectModel(project, outcomes, completed);
    if (uuidField) {
      // Don't overwrite if no uuidField, the models already has a new one
      projectModel.uuid = uuidField;
    }

    const creationDateTime = parseInt(creationDateTimeText, 10);
    if (creationDateTime) {
      projectModel.creationDateTime = creationDateTime;
    }

    const completionDateTime = parseInt(completionDateTimeText, 10);
    if (completionDateTime) {
      projectModel.completionDateTime = completionDateTime;
    }

    return projectModel;
  }

  private generateProjectLine(projectModel: ProjectModel): string {
    let projectLine = "";

    if (projectModel.completed) {
      projectLine = "X ";
    }

    projectLine += projectModel.project;

    for (const outcome of projectModel.outcomes) {
      projectLine += " $" + outcome;
    }

    projectLine += " uuid:" + projectModel.uuid; // Project always has a creation date in integration test, //  if statement only used for importing an old version

    /* istanbul ignore else */
    if (projectModel.creationDateTime) {
      projectLine += " creationDateTime:" + projectModel.creationDateTime;
    }

    if (projectModel.completionDateTime) {
      projectLine += " completionDateTime:" + projectModel.completionDateTime;
    }

    projectLine += "\n";

    return projectLine;
  }

  private async saveProjectToFile(projectModel: ProjectModel): Promise<void> {
    const projectLine = this.generateProjectLine(projectModel);
    await this.fileHelper.appendFile(projectLine);
  }

  // Not used in integration as we only use this for import export
  /* istanbul ignore next */
  private async saveAllProjects(projectModels: ProjectModel[]): Promise<void> {
    let projectText = "";
    for (const projectModel of projectModels) {
      projectText += this.generateProjectLine(projectModel);
    }
    await this.fileHelper.saveTextFile(projectText);
  }
}
