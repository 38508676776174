import { DependencyInjectionUtils } from "../../../util/DependencyInjectionUtils";
import { ProjectService } from "../../ProjectService";
import { ProjectViewModel } from "../../viewModels/ProjectViewModel";
import { IExecutableBotCommand } from "../IBotCommand";
import { IBotCommandResult } from "../IBotCommandResult";

export class ListProjectsBotQuery implements IExecutableBotCommand {
  public readonly commandName = "lp";
  public readonly description = "List projects";
  public readonly smartButtonText = "List projects";

  constructor(private readonly projectService: ProjectService) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async execute(): Promise<IBotCommandResult> {
    const feedback = await this.listProjects();
    return {
      commandName: this.commandName,
      feedback,
    };
  }

  public async listProjects(): Promise<string> {
    const projectMessage = this.formatProjectToText();
    return projectMessage;
  }

  // Can't test this in integration
  /* istanbul ignore next */
  public async getAutoCompleteKeywords(
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    commandInput: string,
  ): Promise<string[]> {
    return Promise.resolve([]);
  }

  private async formatProjectToText() {
    let projectMessage = "";
    // TODO: Group projects by context

    const projectsWithoutNextActions =
      await this.projectService.getUncompletedProjectsWithoutNextActions();
    const otherProjects =
      await this.projectService.getUncompletedProjectsWithNextActions();

    projectMessage += this.formatProjectsWithTasks(otherProjects, false);
    projectMessage += this.formatProjectsWithoutTasks(
      projectsWithoutNextActions,
    );

    return projectMessage;
  }

  private formatProjectsWithoutTasks(
    projectsWithoutNextActions: ProjectViewModel[],
  ) {
    let result = "";
    if (projectsWithoutNextActions.length > 0) {
      result += "\nProjects without next actions:\n";
      result += this.formatProjectsWithTasks(projectsWithoutNextActions, true);
    }
    return result;
  }

  private formatProjectsWithTasks(
    projects: ProjectViewModel[],
    ident: boolean,
  ) {
    let projectMessage = "";
    for (const project of projects) {
      if (ident) {
        projectMessage += "  ";
      }

      projectMessage += project.displayId + ".\t+" + project.project;
      for (const outcome of project.outcomes) {
        projectMessage += "\t\t$" + outcome;
      }
      projectMessage += "\n";
    }
    return projectMessage;
  }
}
