import { INotifyMessage } from "./INotifyMessage";

export class NotifyMessage implements INotifyMessage {
  public data?: object;
  public topic: string = "";

  constructor(topic: string, data?: object) {
    this.topic = topic;
    this.data = data;
  }

  public getTopic(): string {
    return this.topic;
  }
}
