import { DependencyInjectionUtils } from "../../util/DependencyInjectionUtils";
import { ProjectService } from "../ProjectService";
import { TaskService } from "../TaskService";
import { ProjectViewModel } from "../viewModels/ProjectViewModel";
import { TaskViewModel } from "../viewModels/TaskViewModel";

export class SmartGenerator {
  constructor(
    private readonly taskService: TaskService,
    private readonly projectService: ProjectService,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async generateUncompletedTasks(): Promise<string[]> {
    const tasks = await this.taskService.getUncompletedTasks();
    return Promise.all(
      tasks.map((task: TaskViewModel) => {
        return this.generateTaskText(task);
      }),
    );
  }

  public async generateAllUnCompletedProjects(): Promise<string[]> {
    const projects = await this.projectService.getNotCompleted();

    return Promise.all(
      projects.map((project: ProjectViewModel) => {
        return this.generateProjectText(project);
      }),
    );
  }

  public async generateTaskText(taskModel: TaskViewModel): Promise<string> {
    let todoTxtLine = "";

    todoTxtLine += taskModel.displayId + " ";

    todoTxtLine += taskModel.task;

    for (const context of taskModel.contexts) {
      todoTxtLine += " @" + context;
    }

    for (const project of taskModel.projects) {
      todoTxtLine += " +" + project;
      // Remove outcomes when it's possible to update projects
      todoTxtLine += await this.generateOutcomesText(project);
    }

    for (const link of taskModel.links) {
      todoTxtLine += " " + link;
    }

    return todoTxtLine;
  }

  public async generateProjectText(
    projectModel: ProjectViewModel,
  ): Promise<string> {
    let projectLine = "";
    projectLine += projectModel.displayId + " ";
    projectLine += projectModel.project;
    projectLine += await this.generateOutcomesText(projectModel.project);
    return projectLine;
  }

  private async generateOutcomesText(projectName: string): Promise<string> {
    const projectModel = await this.projectService.findProject(projectName); // Can not be covered in integration tests
    /* istanbul ignore next */
    if (projectModel === undefined) {
      return "";
    }
    return projectModel.outcomes
      .map((outcome: string) => {
        return " $" + outcome;
      })
      .join("");
  }
}
