import { IRepository } from "../domain/IRepository";
import { IIdentifiable } from "../domain/domainModels/IIdentifiable";
import { IPageResult } from "./IPageResult";
import { PagedJsonModelRepository } from "./PagedJsonModelRepository";
import { ApiRepository } from "./ApiRepository";
import { BrowserOrNode } from "../util/BrowserOrNode";

/* istanbul ignore file */ // Not used during integration test when azure is turned off.
export class PagedApiRepository<T extends IIdentifiable>
  extends ApiRepository<T>
  implements IRepository<T>
{
  public static readonly APP_CACHE_COUNT = 0; // Increase this when a change in the code requires invalidating all caches

  public override async getAll(): Promise<T[]> {
    const headers = await this.getHeaders();

    const clientRepoCacheCount = this.getClientRepoCacheCount();

    let page = 1;
    let models: T[] = [];
    let pageResult: IPageResult<T>;
    do {
      const url =
        this.repositoryUri + "/page/" + page + "?c=" + this.getUriCacheKey();
      const pageJson = await this.restUtils.get(url, headers);
      pageResult = (await pageJson.json()) as IPageResult<T>;

      if (pageResult.cacheCount != clientRepoCacheCount) {
        // The cache count has been increased serverside. Discard results sofar and try again with the new cache key
        // This is not much of a performance issue as the old results came from the http cache
        this.setClientRepoCacheCount(pageResult.cacheCount);
        return this.getAll();
      }

      models = models.concat(pageResult.modelsInPage);
      page++;
    } while (
      pageResult.modelsInPage.length === PagedJsonModelRepository.PageSize
    );

    return models;
  }

  private getUriCacheKey() {
    return (
      PagedApiRepository.APP_CACHE_COUNT + "." + this.getClientRepoCacheCount()
    );
  }

  /* istanbul ignore next */ // spied away and node and browser  specific code
  private getClientRepoCacheCount(): number {
    if (BrowserOrNode.isNode()) {
      return this.nodeClientRepoCacheCount;
    }

    const cacheCount = window.localStorage.getItem(
      this.getClientCacheCountkey(),
    );
    if (!cacheCount) {
      return 0;
    }

    return Number(cacheCount);
  }

  private nodeClientRepoCacheCount = 0;

  /* istanbul ignore next */ // spied away and node and browser  specific code
  private setClientRepoCacheCount(cacheCount: number) {
    if (BrowserOrNode.isNode()) {
      this.nodeClientRepoCacheCount = cacheCount;
      return;
    }

    // TODO: Should we move client side storage over to indexdb and put it behind some interface to make it also work withing node?
    // IndexDB can also be used from webworkers and has greater storage. Maybe an IndexDBRepository so we can just use something else on node?
    window.localStorage.setItem(
      this.getClientCacheCountkey(),
      cacheCount.toString(),
    );
    if (!cacheCount) return 0;
  }

  /* istanbul ignore next */ // TODO: Test
  private getClientCacheCountkey(): string {
    return "api-repository-cache-count-" + this.type;
  }
}
