import * as Uuid from "uuid";

import { epoch } from "../Types";
import { IDomainModel } from "./IDomainModel";
import { Validate } from "../validators/Validate";

export class ProjectModel implements IDomainModel {
  public modelVersion = 3;
  public __type: string = "ProjectModel";

  public uuid: string;
  public project: string;
  public outcomes: string[];
  public completed: boolean;
  public completionDateTime?: epoch;
  public creationDateTime?: epoch;

  protected static expectedProperties = [
    "modelVersion",
    "__type",
    "uuid",
    "project",
    "outcomes",
    "completed",
    "completionDateTime",
    "creationDateTime",
  ];

  protected validateExpectedProperties() {
    Validate.expectedProperties(this, ProjectModel.expectedProperties);
  }

  constructor(project = "", outcomes: string[] = [], completed = false) {
    this.uuid = Uuid.v4();
    this.project = project;
    this.outcomes = outcomes;
    this.completed = completed;
  }

  public updateValues(projectModel: ProjectModel) {
    this.project = projectModel.project;
    this.outcomes = projectModel.outcomes;
    this.completed = projectModel.completed;
    this.completionDateTime = projectModel.completionDateTime;
    this.creationDateTime = projectModel.creationDateTime;
  }

  public validate() {
    this.validateExpectedProperties();
    Validate.Uuidv4(this.uuid);
    Validate.number(this.modelVersion);
    Validate.string(this.project);
    Validate.stringArray(this.outcomes);
    Validate.boolean(this.completed);
    Validate.optionalEpoch(this.completionDateTime);
    Validate.optionalEpoch(this.creationDateTime);
  }
}
