import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { DomainEventBus } from "./pubsub/DomainEventBus";
import { TaskModel } from "./domainModels/TaskModel";
import { FunctionalError } from "./errors/FunctionalError";
import { AddTaskEventModel } from "./events/AddTaskEventModel";
import { CompleteTaskEventModel } from "./events/CompleteTaskEventModel";
import { TaskMaybeEventModel } from "./events/TaskMaybeEventModel";
import { UpdateTaskEventModel } from "./events/UpdateTaskEventModel";
import { IReadOnlyRepository } from "./IReadonlyRepository";
import { TaskViewModel } from "./viewModels/TaskViewModel";

export class TaskService {
  constructor(
    private readonly taskView: IReadOnlyRepository<TaskViewModel>, // InMemoryRepository
    private readonly eventBus: DomainEventBus,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async add(taskModel: TaskModel): Promise<void> {
    const event = new AddTaskEventModel(taskModel);
    await this.eventBus.publish(event);
  }

  public async updateTask(taskModel: TaskModel): Promise<void> {
    /* istanbul ignore else */ // At the moment impossible from integration
    if (await this.isExistingTask(taskModel.uuid)) {
      const event = new UpdateTaskEventModel(taskModel);
      await this.eventBus.publish(event);
    } else {
      throw new FunctionalError("invalid task uuid");
    }
  }

  public async getTask(index: number): Promise<TaskViewModel> {
    const tasks = await this.getTasks();
    const task = tasks.find((t) => t.displayId === index);

    if (!task) {
      throw new FunctionalError("invalid task number");
    }

    return task;
  }

  public async getTasks(): Promise<TaskViewModel[]> {
    return this.taskView.getAll();
  }

  public async getUncompletedTasks(project?: string): Promise<TaskViewModel[]> {
    const tasks = await this.getTasks();
    return tasks.filter((t) => {
      return (
        !t.completed && (project === undefined || t.projects.includes(project))
      );
    });
  }

  public async getCurrentTasks(): Promise<TaskViewModel[]> {
    const tasks = await this.getTasks();
    return tasks.filter((t) => {
      return !t.completed && !t.maybe;
    });
  }

  public async getMaybeTasks(): Promise<TaskViewModel[]> {
    const tasks = await this.getTasks();
    return tasks.filter((t) => {
      return !t.completed && t.maybe;
    });
  }

  public async completeTask(index: number): Promise<void> {
    const task = await this.getTask(index);
    const event = new CompleteTaskEventModel(task.uuid);
    await this.eventBus.publish(event);
  }

  public async updateProjectName(
    oldProjectName: string,
    newProjectName: string,
  ): Promise<void> {
    const projectTasks = await this.getByProject(oldProjectName);
    for (const task of projectTasks) {
      const replacedProjects = this.replaceProject(
        task.projects,
        oldProjectName,
        newProjectName,
      );
      task.projects = replacedProjects;
      await this.updateTask(task);
    }
  }

  public async toggleMaybe(task: TaskModel): Promise<boolean> {
    const newMaybeState = !task.maybe;
    const event = new TaskMaybeEventModel(task.uuid, newMaybeState);
    await this.eventBus.publish(event);
    return newMaybeState;
  }

  public async getByProject(projectName: string) {
    const tasks = await this.getTasks();
    return tasks.filter((t) => {
      return t.projects.includes(projectName);
    });
  }

  private replaceProject(
    projects: string[],
    oldProjectName: string,
    newProjectName: string,
  ): string[] {
    const index = projects.indexOf(oldProjectName); // should never happen
    /* istanbul ignore else */
    if (index !== -1) {
      projects[index] = newProjectName;
    } else {
      throw new Error("Old project name not found");
    }
    return projects;
  }

  private async isExistingTask(uuid: string | undefined): Promise<boolean> {
    const tasks = await this.getTasks();
    return !!tasks.find((t) => t.uuid === uuid);
  }
}
