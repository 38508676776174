import { DependencyInjectionUtils } from "../../../util/DependencyInjectionUtils";
import { TaskService } from "../../TaskService";
import { IExecutableBotCommand } from "../IBotCommand";
import { IBotCommandResult } from "../IBotCommandResult";

export class MaybeBotCommand implements IExecutableBotCommand {
  public readonly commandName = "m";
  public readonly description = "Toggle a task maybe status";
  public readonly smartButtonText = "Toggle maybe";
  public readonly argumentDescription = "tasknumber";

  constructor(private readonly taskService: TaskService) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async execute(taskNumberString: string): Promise<IBotCommandResult> {
    const taskNumber = parseInt(taskNumberString, 10);
    const feedback = await this.toggleMaybe(taskNumber);
    return {
      commandName: this.commandName,
      feedback,
    };
  }

  public async toggleMaybe(index: number): Promise<string> {
    const task = await this.taskService.getTask(index);
    const maybe = await this.taskService.toggleMaybe(task);

    return maybe
      ? "For some other day: " + task.task
      : "Back to current tasks: " + task.task;
  }

  // Can't test this in integration
  /* istanbul ignore next */
  public async getAutoCompleteKeywords(
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    commandInput: string,
  ): Promise<string[]> {
    return Promise.resolve([]);
  }
}
