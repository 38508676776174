import { DependencyInjectionUtils } from "../../../util/DependencyInjectionUtils";
import { BotService } from "../BotService";
import { IExecutableBotCommand } from "../IBotCommand";
import { IBotCommandResult } from "../IBotCommandResult";

export class HelpBotQuery implements IExecutableBotCommand {
  public readonly commandName = "help";
  public readonly description = "Print this help message";
  public readonly smartButtonText = "Help";

  private botService!: BotService;

  constructor() {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async execute(): Promise<IBotCommandResult> {
    let feedback = "Command are:\n";
    for (const botCommand of this.botService.listExecutableCommands()) {
      feedback += botCommand.commandName;
      if (botCommand.argumentDescription) {
        feedback += " " + botCommand.argumentDescription;
      }
      feedback += "\n";
      feedback += botCommand.description + "\n";
      feedback += "\n";
    }
    return {
      commandName: this.commandName,
      feedback,
    };
  }

  // Can't test this in integration
  /* istanbul ignore next */
  public async getAutoCompleteKeywords(
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    commandInput: string,
  ): Promise<string[]> {
    return Promise.resolve([]);
  }

  public injectBotService(botservice: BotService) {
    // Use method injection as a workaround for a circular dependency
    this.botService = botservice;
  }
}
