import { DomainEventModel } from "../../domain/events/DomainEventModel";
import { ModelCloner } from "../../util/ModelCloner";
import { IMigrator } from "../IMigrator";
import { renameUrlsToLinksInTaskModel } from "./scripts/20220831-renameUrlsToLinksInTaskModel";
import { renameLaterToMaybeInTaskModel } from "./scripts/20221003-renameLaterToMaybeInTaskModel";
import { renameTaskLaterEventModelToMaybe } from "./scripts/20221003-renameTaskLaterEventModelToMaybe";
import { addMissingTypeFields } from "./scripts/20240315-addMissingTypeFields";
import { removeNullFromOutcomes } from "./scripts/20240408-removeNullFromOutcomes";
import { migrateGoalsToOutcomes } from "./scripts/20240425-migrateGoalsToOutcomes";
import { removePreValidationDeprecatedDataFromModels } from "./scripts/20240425-removePreValidationDeprecatedDataFromModels";
import { convertTypes } from "./scripts/convertTypes";
import { validateMigratedEvents } from "./scripts/validateMigratedEvents";

export class EventMigrator implements IMigrator<DomainEventModel> {
  public migrate(allEvents: DomainEventModel[]): DomainEventModel[] {
    const clonedEvents = ModelCloner.clone(allEvents);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return clonedEvents.map((event: any) => {
      removePreValidationDeprecatedDataFromModels(event);
      renameUrlsToLinksInTaskModel(event);
      renameLaterToMaybeInTaskModel(event);
      renameTaskLaterEventModelToMaybe(event);
      addMissingTypeFields(event);
      migrateGoalsToOutcomes(event);
      removeNullFromOutcomes(event);

      // Convert type as second last step as this converts to the newest model and we have to migrate up to that first.
      event = convertTypes(event);
      // Validate as last step to check for errors
      validateMigratedEvents(event);

      return event;
    });
  }
}
