import { ProjectModel } from "../domainModels/ProjectModel";
import { Validate } from "../validators/Validate";
import { DomainEventModel } from "./DomainEventModel";

export class UpdateProjectEventModel extends DomainEventModel {
  public __type: string = "UpdateProjectEventModel";
  public modelVersion = 2;
  public static eventNameConstant = "UpdateProjectEvent";

  public eventName = UpdateProjectEventModel.eventNameConstant;

  protected static override expectedProperties = [
    "modelVersion",
    "__type",
    "eventName",
    "projectModel",
  ].concat(super.expectedProperties);

  protected validateExpectedProperties() {
    Validate.expectedProperties(
      this,
      UpdateProjectEventModel.expectedProperties,
    );
  }

  constructor(public projectModel: ProjectModel) {
    super();
  }

  public override validate() {
    this.validateExpectedProperties();
    this.projectModel.validate();
    super.validate();
  }
}
