import * as PLimit from "p-limit";

import { IIdentifiable } from "../domain/domainModels/IIdentifiable";
import { IFileSystem } from "../domain/IFileSystem";
import { IModelPersister } from "../domain/IModelPersister";
import { IRepository } from "../domain/IRepository";
import { timestamp } from "../domain/Types";
import { DateTimeUtils } from "../util/DateTimeUtils";
import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { FileSystemUtils } from "../util/FileSystemUtils";
import { ModelCloner } from "../util/ModelCloner";
import { AbstractRepository } from "./AbstractRepository";
import { LogService } from "../logging/LogService";

export class JsonModelRepository<T extends IIdentifiable>
  extends AbstractRepository<T>
  implements IRepository<T>
{
  public static readonly concurrencyLimit = 10;

  constructor(
    protected readonly modelPersister: IModelPersister,
    protected readonly fileSystem: IFileSystem,
    protected readonly logService: LogService,
    protected readonly repositoryPath: () => string,
    protected readonly dataFolder: () => string,
    protected readonly type: string,
  ) {
    super();
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async getAll(): Promise<T[]> {
    const files = await this.getModelFiles();
    return await this.getModels(files);
  }

  protected async getModels(files: string[]) {
    const limit = PLimit(JsonModelRepository.concurrencyLimit);

    const fetchJobs = files.map((file) => {
      return limit(() => {
        const filePath = this.repositoryPath() + "/" + file;
        return this.modelPersister.getModel<T>(filePath);
      });
    });

    const models = await Promise.all(fetchJobs);
    await this.validateInvalidFileNames(models, files);

    return models;
  }

  public async save(model: T): Promise<timestamp> {
    await this.saveModel(model);
    return this.writeTimestamp();
  }

  public override async tryGetByUuid(uuid: string): Promise<T | undefined> {
    const filepath = await this.generateFilepath(uuid);
    try {
      return await this.modelPersister.getModel<T>(filepath);
    } catch (e) {
      const err = e as Error;
      /* istanbul ignore next */ // The else code only triggers on real unexpected exceptions. Second an third includes only on azure.
      if (
        err.message.includes("ENOENT") ||
        err.message.includes("The specified parent path does not exist") ||
        err.message.includes("The specified resource does not exist")
      ) {
        return undefined;
      } else {
        throw e;
      }
    }
  }

  public async deleteAll(): Promise<timestamp> {
    await this.fileSystem.removeDataSubDir(this.repositoryPath());
    await this.fileSystem.mkdir(this.repositoryPath());
    return this.writeTimestamp();
  }

  /* istanbul ignore next */ // JsonModelRepository isn't counted during integration as it is run serverside
  // some other functions are used during import export
  public async getTimestamp(): Promise<timestamp> {
    const timestampFilename = await this.getTimestampFilename();
    let repoTimeStamp;
    try {
      repoTimeStamp = await this.fileSystem.readFile(timestampFilename);
      return BigInt(repoTimeStamp);
      // No hit during integration
    } catch (error) /* istanbul ignore next*/ {
      if (FileSystemUtils.isNoSuchFileOrDirectoryError(error as Error)) {
        // new file
        return this.writeTimestamp();
      } else {
        // If anything else goes wrong return a new timestamp
        void this.logService.logWarning(error as Error);
        void this.logService.logWarningMessage(
          `Error in getTimestamp. timestampFilename ${timestampFilename} repoTimeStamp ${repoTimeStamp}`,
        );
        return this.writeTimestamp();
      }
    }
  }

  // hard to test during integration
  /* istanbul ignore next */
  public override async delete(uuid: string): Promise<timestamp> {
    const filepath = await this.generateFilepath(uuid);
    await this.fileSystem.deleteFile(filepath);
    return this.writeTimestamp();
  }

  public override async import(models: T[]): Promise<timestamp> {
    const limit = PLimit(JsonModelRepository.concurrencyLimit);

    const importTaskJobs = models.map((model) => {
      return limit(() => this.saveModel(model));
    });
    await Promise.all(importTaskJobs);

    return this.writeTimestamp();
  }

  private async saveModel(model: T): Promise<void> {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const modelAny = model as any;
    /* istanbul ignore next */ // cloneWithoutViewProperties not tested during integration. TODO: Remove when solving TODO below
    const clone =
      typeof modelAny["cloneWithoutViewProperties"] === "function"
        ? modelAny.cloneWithoutViewProperties() // TODO: Deze hack ombouwen naar code binnen ModelCloner die scant op een speciale property?
        : ModelCloner.clone(model);
    const filepath = await this.generateFilepath(clone.uuid);
    await this.modelPersister.saveModel(clone, filepath);
  }

  protected async validateInvalidFileNames(models: T[], files: string[]) {
    models.forEach((model, index) => {
      const expectedFilename = model.uuid + ".json"; // Can't be reached in integration test
      /* istanbul ignore if */
      if (expectedFilename !== files[index]) {
        // index can be used here because the arrays are in the same order
        throw new Error(
          `Invalid file name: ${files[index]} should be ${expectedFilename} `,
        );
      }
    });
  }

  private async generateFilepath(uuid: string) {
    return this.repositoryPath() + "/" + uuid + ".json";
  }

  protected async getModelFiles() {
    const directory = await this.fileSystem.readDir(this.repositoryPath());
    return directory.filter((d) => d.endsWith(".json"));
  }

  private async writeTimestamp(): Promise<timestamp> {
    const currentTimestamp = DateTimeUtils.getCurrentTimestamp();
    await this.fileSystem.saveTextFile(
      await this.getTimestampFilename(),
      currentTimestamp.toString(),
    );
    return currentTimestamp;
  }

  private async getTimestampFilename(): Promise<string> {
    return this.dataFolder() + "/" + this.type + ".timestamp";
  }
}
