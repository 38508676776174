import { IDomainModel } from "../domain/domainModels/IDomainModel";
import { IEncryptedModel } from "../domain/domainModels/IEncryptedModel";
import { EncryptionPasswordService } from "../domain/EncryptionPasswordService";
import { IRepository } from "../domain/IRepository";
import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { ModelEncrypter } from "../util/ModelEncrypter";
import { timestamp } from "../domain/Types";
import { AbstractRepository } from "./AbstractRepository";

export class EncryptedRepository<T extends IDomainModel>
  extends AbstractRepository<T>
  implements IRepository<T>
{
  constructor(
    private readonly dataRepository: IRepository<IEncryptedModel>,
    private readonly encryptionPasswordService: EncryptionPasswordService,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
    super();
  }

  public async save(model: T): Promise<timestamp> {
    const encryptionKey =
      await this.encryptionPasswordService.getDataEncryptionKey();
    const encryptedModel = ModelEncrypter.encryptModel(model, encryptionKey);
    return this.dataRepository.save(encryptedModel);
  }

  public async deleteAll(): Promise<timestamp> {
    return this.dataRepository.deleteAll();
  }

  /* istanbul ignore next */ // We don't use delete as we currently only append encrypted data in the app.
  public async delete(uuid: string): Promise<timestamp> {
    return this.dataRepository.delete(uuid);
  }

  public async getAll(): Promise<T[]> {
    const encryptedModels = await this.dataRepository.getAll();
    const encryptionKey =
      await this.encryptionPasswordService.getDataEncryptionKey();

    const decryptedModels: T[] = [];
    for (const model of encryptedModels) {
      const decryptedModel = ModelEncrypter.decryptModel(model, encryptionKey);
      decryptedModels.push(decryptedModel as T);
    }

    return Promise.resolve(decryptedModels);
  }

  /* istanbul ignore next */ // Unused during integration
  public override async tryGetByUuid(uuid: string): Promise<T | undefined> {
    const encryptionKey =
      await this.encryptionPasswordService.getDataEncryptionKey();
    const encryptedModel = await this.dataRepository.tryGetByUuid(uuid);
    if (!encryptedModel) {
      return undefined;
    }
    return ModelEncrypter.decryptModel(encryptedModel, encryptionKey) as T;
  }

  /* istanbul ignore next */ // Not used during integration
  public async getTimestamp(): Promise<timestamp> {
    return this.dataRepository.getTimestamp();
  }

  public override async import(models: T[]): Promise<timestamp> {
    const encryptionKey =
      await this.encryptionPasswordService.getDataEncryptionKey();

    const encryptedModels: IEncryptedModel[] = [];

    for (const model of models) {
      const encryptedModel = ModelEncrypter.encryptModel(model, encryptionKey);
      encryptedModels.push(encryptedModel);
    }

    return this.dataRepository.import(encryptedModels);
  }
}
