import { Validate } from "../validators/Validate";
import { DomainEventModel } from "./DomainEventModel";

export class CompleteTaskEventModel extends DomainEventModel {
  public __type: string = "CompleteTaskEventModel";
  public modelVersion = 2;
  public static eventNameConstant = "CompleteTaskEventModel";

  public eventName = CompleteTaskEventModel.eventNameConstant;

  protected static override expectedProperties = [
    "modelVersion",
    "__type",
    "eventName",
    "taskUuid",
  ].concat(super.expectedProperties);

  protected validateExpectedProperties() {
    Validate.expectedProperties(
      this,
      CompleteTaskEventModel.expectedProperties,
    );
  }

  constructor(public taskUuid: string) {
    super();
  }

  public override validate() {
    this.validateExpectedProperties();
    Validate.Uuidv4(this.taskUuid);
    super.validate();
  }
}
