import { IRepository } from "../domain/IRepository";
import { timestamp } from "../domain/Types";
import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { RestUtils } from "../util/RestUtils";
import { ConfigurationManager } from "../config/ConfigurationManager";
import { AbstractRepository } from "./AbstractRepository";
import { IIdentifiable } from "../domain/domainModels/IIdentifiable";
import { EncryptionPasswordService } from "../domain/EncryptionPasswordService";

/* istanbul ignore file */ // Not used during integration test when azure is turned off.
export class ApiRepository<T extends IIdentifiable>
  extends AbstractRepository<T>
  implements IRepository<T>
{
  public static BRAINSUPPORTER_TESTUUID_HEADER = "brainsupporter-testuuid";

  constructor(
    protected restUtils: RestUtils,
    private configurationManager: ConfigurationManager,
    protected readonly repositoryUri: string,
    protected readonly type: string,
  ) {
    // Remove last argument from check because it can be null for a valid reason

    const xArguments = [...arguments];
    DependencyInjectionUtils.validateDependenciesDefined(
      xArguments.slice(0, -1),
    );
    super();
  }

  public override async tryGetByUuid(uuid: string): Promise<T | undefined> {
    const headers = await this.getHeaders();

    const url = this.repositoryUri + "/" + uuid;
    const result = await this.restUtils.get(url, headers);

    if (result.status === 204) {
      return undefined;
    } else {
      return result.json();
    }
  }

  public async getAll(): Promise<T[]> {
    const headers = await this.getHeaders();
    const result = await this.restUtils.get(this.repositoryUri, headers);
    return result.json();
  }

  public async save(model: T): Promise<timestamp> {
    const headers = await this.getHeaders();
    return this.restUtils.post(this.repositoryUri, model, headers);
  }

  public async deleteAll(): Promise<timestamp> {
    const headers = await this.getHeaders();
    return this.restUtils.delete(this.repositoryUri, headers);
  }

  public async getTimestamp(): Promise<timestamp> {
    const headers = await this.getHeaders();
    const result = await this.restUtils.get(
      this.repositoryUri + "/timestamp",
      headers,
    );
    return result.json();
  }

  public async delete(uuid: string): Promise<timestamp> {
    const headers = await this.getHeaders();
    return this.restUtils.delete(this.repositoryUri + "/" + uuid, headers);
  }

  public override async import(models: T[]): Promise<timestamp> {
    const headers = await this.getHeaders();
    return this.restUtils.post(this.repositoryUri + "/import", models, headers);
  }

  protected async getHeaders(): Promise<HeadersInit | undefined> {
    const headers: HeadersInit | undefined = [];

    this.setTimestampHeader(headers);

    if (this.configurationManager.testUuid) {
      headers.push([
        ApiRepository.BRAINSUPPORTER_TESTUUID_HEADER,
        this.configurationManager.testUuid,
      ]);
    }
    return headers;
  }

  private setTimestampHeader(headers: [string, string][]): void {
    const timestamp = EncryptionPasswordService.encryptionKeyTimestamp;
    if (timestamp) {
      headers.push(["x-brainsupporter-key-timestamp", timestamp.toString()]);
    }
  }
}
