import { ArrayUtils } from "../util/ArrayUtils";
import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { TextUtils } from "../util/TextUtils";
import { ContextService } from "./ContextService";
import { TechnicalError } from "./errors/TechnicalError";
import { ProjectService } from "./ProjectService";
import { SmartCaptureModel } from "./SmartCapture/SmartCaptureModel";
import { SmartParser } from "./SmartCapture/SmartParser";

// TODO: Test met contexten en spaties en autocomplete (in browser handMatg en unit)
// VB: "@test t" en "@test"
// TODO: Autocomplete werkt niet halverwege met ut. Vb: ut 1234 aaaa at_strea<tab> url  of add taak @contex<tab> @contextdienadecursorstaat

export class AutoCompleteService {
  private readonly smartParser = new SmartParser();

  constructor(
    private readonly projectService: ProjectService,
    private readonly contextService: ContextService,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async getEditAutoCompleteKeywords(
    commandInput: string,
  ): Promise<string[]> {
    const parsed = this.smartParser.parseCaptureText(commandInput);
    const allKeywords = await this.getAllKeywords();
    const possibleKeywords = await this.filterExistingKeywords(
      parsed,
      allKeywords,
    );

    return this.getAutoCompleteKeywords(
      commandInput,
      possibleKeywords,
      allKeywords,
    );
  }

  public async getAutoCompleteKeywords(
    commandInput: string,
    possibleKeywords: string[],
    allKeywords?: string[],
  ): Promise<string[]> {
    allKeywords ??= possibleKeywords;

    const filteredKeywords = this.filterPartialAutocompleteKeywords(
      commandInput,
      possibleKeywords,
    );

    if (
      filteredKeywords.length === 0 &&
      allKeywords.some((k) => TextUtils.endsWithIgnoreCase(commandInput, k))
    ) {
      // TODO: "add<tab>" should become "add "
      // The end is something we know, so add a space which isn't there yet
      return [" "];
    }

    return filteredKeywords;
  }

  private filterPartialAutocompleteKeywords(
    commandInput: string,
    keywords: string[],
  ) {
    const lastParts = this.getPartialAutoCompleteMatches(
      commandInput,
      keywords,
    );

    let partialMatches: string[] = [];
    // When the end of the input already partially matches suggestions
    for (const lastPart of lastParts) {
      const newMatches = keywords.filter((k) =>
        TextUtils.startsWithIgnoreCase(k, lastPart),
      );
      partialMatches = partialMatches.concat(newMatches);
    }
    if (lastParts.length > 0 && partialMatches.length > 0) {
      return partialMatches;
    } else {
      if (commandInput.slice(-1) === " ") {
        return keywords;
      } else {
        return [];
      }
    }
  }

  public applyAutocomplete(commandInput: string, selectedkeyword: string) {
    const partialCompletions = this.getPartialAutoCompleteMatches(
      commandInput,
      [selectedkeyword],
    );

    /* istanbul ignore if */ // This should not be possible as we have 1 keyword
    if (partialCompletions.length > 1) {
      throw new TechnicalError("1 selectedkeyword but more matches");
    }

    const partialCompletion = partialCompletions.pop(); // undefined or the only element

    let extraSpaceInBetween = "";
    if (
      commandInput.length > 0 &&
      commandInput.slice(-1) !== " " &&
      selectedkeyword.slice(0) !== " "
    ) {
      extraSpaceInBetween = " ";
    }

    let extraSpaceAtEnd = "";
    if (selectedkeyword.slice(-1) !== " ") {
      extraSpaceAtEnd = " ";
    }

    if (
      partialCompletion &&
      TextUtils.startsWithIgnoreCase(selectedkeyword, partialCompletion)
    ) {
      return (
        commandInput.slice(0, -1 * partialCompletion.length) +
        selectedkeyword +
        extraSpaceAtEnd
      );
    } else {
      return (
        commandInput + extraSpaceInBetween + selectedkeyword + extraSpaceAtEnd
      );
    }
  }

  public static addSpaceAfterNumberArgument(commandInput: string) {
    let input = commandInput;
    // Test to see if we have a command and a number.
    if (input.split(" ").length === 2 && TextUtils.endsWithNumber(input)) {
      input += " "; // Add a space to stop matching other partial numbers
    }
    return input;
  }

  private async getAllKeywords() {
    const allContexts = await this.contextService.getAutoCompleteContexts(true);
    const allProjects = await this.projectService.getAutoCompleteProjects();
    // TODO: Add tags
    return allContexts.concat(allProjects);
  }

  private async filterExistingKeywords(
    parsed: SmartCaptureModel,
    keywords: string[],
  ) {
    let result = ArrayUtils.difference(
      keywords,
      ContextService.addAtSign(parsed.contexts),
    );
    result = ArrayUtils.difference(
      result,
      ProjectService.addPlusSign(parsed.projects),
    );

    // TODO: Add tags
    return result;
  }

  private getPartialAutoCompleteMatches(
    input: string,
    keywords: string[],
  ): string[] {
    const result = [];

    for (const keyword of keywords) {
      for (let i = 0; i < keyword.length; i++) {
        const partialMatchAttempt = keyword.slice(0, keyword.length - i);
        if (TextUtils.endsWithIgnoreCase(input, partialMatchAttempt)) {
          const charBeforeCompletion = input.charAt(
            input.length - partialMatchAttempt.length - 1,
          );
          if (this.IsDividingChar(charBeforeCompletion)) {
            result.push(partialMatchAttempt);
          }
        }
      }
    }
    return ArrayUtils.distinct(result);
  }

  private IsDividingChar(charBeforeCompletion: string) {
    return (
      charBeforeCompletion === " " ||
      charBeforeCompletion === "" || // This happens getting a char before the beginning. So we complete something frm the beginning
      charBeforeCompletion === "@" ||
      charBeforeCompletion === "+" ||
      charBeforeCompletion === "#" ||
      charBeforeCompletion === "$"
    );
  }
}
