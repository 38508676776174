import { IRepository } from "../domain/IRepository";
import { IValidate } from "../domain/domainModels/IValidate";
import { IIdentifiable } from "../domain/domainModels/IIdentifiable";
import { ValidatedRepository } from "./ValidatedRepository";
import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { IMigrator } from "../migrations/IMigrator";

// TODO: Document MigratedValidatedRepository in puml diagram
export class MigratedValidatedRepository<T extends IIdentifiable & IValidate>
  extends ValidatedRepository<T>
  implements IRepository<T>
{
  constructor(
    protected override readonly repository: IRepository<T>,
    protected readonly migrator: IMigrator<T>,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
    super(repository);
  }

  public override async getAll(): Promise<T[]> {
    const models = await super.getAll();
    const migratedModels = this.migrator.migrate(models);
    return migratedModels;
  }

  /* istanbul ignore next */ // Not yet used during integration
  public override async getByUuid(uuid: string): Promise<T> {
    const model = await super.getByUuid(uuid);
    const migratedModels = this.migrator.migrate([model]);
    return migratedModels[0];
  }

  public override async tryGetByUuid(uuid: string): Promise<T | undefined> {
    const model = await super.tryGetByUuid(uuid);
    /* istanbul ignore else */ // Not yet used during integration
    if (!model) {
      return model;
    } else {
      const migratedModels = this.migrator.migrate([model]);
      return migratedModels[0];
    }
  }
}
