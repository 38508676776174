import { DependencyInjectionUtils } from "../../../util/DependencyInjectionUtils";
import { TaskService } from "../../TaskService";
import { FormattingService } from "../FormattingService";
import { IExecutableBotCommand } from "../IBotCommand";
import { IBotCommandResult } from "../IBotCommandResult";

export class ListTasksBotQuery implements IExecutableBotCommand {
  public static staticCommandName = "lt";
  public readonly commandName = ListTasksBotQuery.staticCommandName;
  public readonly description = "List tasks";
  public readonly smartButtonText = "List tasks";

  constructor(
    private readonly formattingService: FormattingService,
    private readonly taskService: TaskService,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async execute(): Promise<IBotCommandResult> {
    const tasks = await this.taskService.getCurrentTasks();
    const feedback =
      await this.formattingService.formatTaskListByProject(tasks);

    return {
      commandName: this.commandName,
      feedback,
    };
  }

  // Can't test this in integration
  /* istanbul ignore next */
  public async getAutoCompleteKeywords(
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    commandInput: string,
  ): Promise<string[]> {
    return Promise.resolve([]);
  }
}
