import { ArrayUtils } from "../../util/ArrayUtils";
import { DependencyInjectionUtils } from "../../util/DependencyInjectionUtils";
import { AutoContextService } from "../AutoContextService";
import { ProjectModel } from "../domainModels/ProjectModel";
import { TaskModel } from "../domainModels/TaskModel";
import { ProjectService } from "../ProjectService";
import { ProjectViewModel } from "../viewModels/ProjectViewModel";
import { TaskViewModel } from "../viewModels/TaskViewModel";

export class FormattingService {
  constructor(
    private readonly projectService: ProjectService,
    private readonly autoContextService: AutoContextService,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async formatTaskListByProject(
    tasks: TaskViewModel[],
    warnProjectsWithoutTasks: boolean = true,
  ): Promise<string> {
    let formattedOutput = "";

    tasks.sort((a, b) => {
      return a.displayId - b.displayId;
    });

    const { output, remainingTasks } = await this.printTasksByProject(tasks);
    formattedOutput += output;
    tasks = remainingTasks;
    formattedOutput += await this.printRemainingTasks(tasks);
    if (warnProjectsWithoutTasks) {
      formattedOutput += await this.printProjectsWithoutTasks();
    }

    return formattedOutput;
  }

  private async printProjectsWithoutTasks() {
    const context = await this.autoContextService.getContext();

    let formattedOutput = "";

    const projectsWithoutUncompletedTasks =
      await this.projectService.getUncompletedProjectsWithoutNextActions(
        context,
      );

    if (projectsWithoutUncompletedTasks.length > 0) {
      formattedOutput += "Projects without tasks 😐\n";
      for (const project of projectsWithoutUncompletedTasks) {
        formattedOutput += "  +" + this.formatProject(project) + "\n";
      }
    }

    const projectsWithOnlyMaybeTasks =
      await this.projectService.getUncompletedProjectsWithOnlyMaybe(context);

    // TODO: We moved later to maybe and migrated that, but for this I would also want later or someday. How to solve it. Rename maybe as someday/maybe in the UI
    // and keep it "maybe" in the code?
    if (projectsWithOnlyMaybeTasks.length > 0) {
      formattedOutput +=
        "Projects with all remaining tasks marked someday/maybe 🤔\n";
      for (const project of projectsWithOnlyMaybeTasks) {
        formattedOutput += "  +" + this.formatProject(project) + "\n";
      }
    }

    return formattedOutput;
  }

  private formatProject(project: ProjectViewModel) {
    return project.project + " (" + project.displayId + ")";
  }

  private IsContextNotEqualToAutoContext(task: TaskModel, context: string) {
    return !(task.contexts.length === 1 && task.contexts[0] === context);
  }

  private async printRemainingTasks(
    remainingTasks: TaskViewModel[],
  ): Promise<string> {
    let formattedOutput = "";
    if (remainingTasks.length > 0) {
      remainingTasks = await this.filterByAutoContext(remainingTasks);
      formattedOutput +=
        (await this.formatTasksToText(remainingTasks, false)) + "\n";
    }
    return formattedOutput;
  }

  private async printTasksByProject(
    tasks: TaskViewModel[],
  ): Promise<{ output: string; remainingTasks: TaskViewModel[] }> {
    let output = "";
    const projects = await this.projectService.getNotCompleted();

    for (const project of projects) {
      let filteredTasks = this.filterByProject(tasks, project);
      filteredTasks = await this.filterByAutoContext(filteredTasks);
      tasks = ArrayUtils.difference(tasks, filteredTasks);
      if (filteredTasks.length > 0) {
        output += "+" + this.formatProject(project) + "\n";

        output += await this.formatTasksToText(filteredTasks, true);
        output += "\n";
      }
    }
    const remainingTasks = tasks;
    return { output, remainingTasks };
  }

  private filterByProject(
    uncompletedTasks: TaskViewModel[],
    project: ProjectModel,
  ) {
    return uncompletedTasks.filter((t) => {
      return t.projects.includes(project.project);
    });
  }

  private async filterByAutoContext(tasks: TaskViewModel[]) {
    const autoContext = await this.autoContextService.getContext();
    if (autoContext) {
      tasks = tasks.filter((t) => {
        return !!t.contexts.find((context) => {
          return (
            context.toLocaleLowerCase() === autoContext.toLocaleLowerCase()
          );
        });
      });
    }
    return tasks;
  }

  public async formatTasksToText(
    tasks: TaskViewModel[],
    byProject: boolean,
  ): Promise<string> {
    let formattedTaskOutput = "";
    const context = await this.autoContextService.getContext();

    for (const task of tasks) {
      if (byProject) {
        formattedTaskOutput += "  ";
      }
      const hasLinks = task.links.length > 0;

      formattedTaskOutput += task.displayId;
      formattedTaskOutput += hasLinks ? ". 🔗 " : ".    ";
      formattedTaskOutput += task.maybe ? "M " : "  ";
      formattedTaskOutput += task.task;

      if (this.IsContextNotEqualToAutoContext(task, context)) {
        for (const context of task.contexts) {
          formattedTaskOutput += "\t@" + context;
        }
      }

      for (const tag of task.tags) {
        formattedTaskOutput += "\t#" + tag;
      }

      formattedTaskOutput += "\n";
    }
    return formattedTaskOutput;
  }
}
