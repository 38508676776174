import { TaskModel } from "../domainModels/TaskModel";
import { Validate } from "../validators/Validate";
import { DomainEventModel } from "./DomainEventModel";

export class AddTaskEventModel extends DomainEventModel {
  public __type: string = "AddTaskEventModel";
  public modelVersion = 2;
  public static eventNameConstant = "AddTaskEvent";

  public eventName = AddTaskEventModel.eventNameConstant;

  protected static override expectedProperties = [
    "modelVersion",
    "__type",
    "eventName",
    "taskModel",
  ].concat(super.expectedProperties);

  protected validateExpectedProperties() {
    Validate.expectedProperties(this, AddTaskEventModel.expectedProperties);
  }

  constructor(public taskModel: TaskModel) {
    super();
  }

  public override validate() {
    this.validateExpectedProperties();
    this.taskModel.validate();
    super.validate();
  }
}
