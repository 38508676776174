import React, { Component } from "react";

import "./Webconsole.css";

import { BotService } from "brainsupporter-core/lib/domain/bot/BotService";
import { IBotCommandResult } from "brainsupporter-core/lib/domain/bot/IBotCommandResult";
import { ImportBotCommand } from "brainsupporter-core/lib/domain/bot/commands/ImportBotCommand";

import { BrowserFileUtil } from "../../util/BrowserFileUtil";
import { WebCompositionRoot } from "../../WebCompositionRoot";
import NewEncryptionPasswordInput from "../NewEncryptionPasswordInput/NewEncryptionPasswordInput";
import ContextChooser from "../ContextChooser/ContextChooser";
import { AutoCompleteService } from "brainsupporter-core/lib/domain/AutoCompleteService";
import SmartButton from "../SmartButton/SmartButton";
import { Notifier } from "brainsupporter-core/lib/domain/pubsub/Notifier";
import { NotifyMessage } from "brainsupporter-core/lib/domain/pubsub/NotifyMessage";
import TaskList from "../TaskList/TaskList";

// TODO: Build slider to manually enable mobile functions?

// TODO: Implement arrow navigating like zsh.?
// See https://youtu.be/ZhFFs5ltNDs?si=IoXOMfWanjMtKhzJ&t=7

// TODO-RAS: implement cycling by multiple tabs and returning to the original

type WebconsoleState = {
  autocompleteList: string[];
  autocompleteActive: boolean;
  command: string;
  enterNewPasswords: boolean;
  feedbackItems: string[];
  newPassword: string;
  newPasswordConfirmation: string;
};

type WebConsoleProps = {
  errorMessage: (errorMessage: string) => void;
  setLoading: (setLoading: boolean) => void;
  root: WebCompositionRoot;
};

class Webconsole extends Component<WebConsoleProps, WebconsoleState> {
  root: WebCompositionRoot;
  botService: BotService;
  autoCompleteService: AutoCompleteService;
  notifier: Notifier<NotifyMessage>;
  private static alreadyMounted = false;

  constructor(props: WebConsoleProps) {
    super(props);
    this.root = this.props.root;
    this.botService = this.root.BotService;
    this.autoCompleteService = this.root.AutoCompleteService;
    this.notifier = this.root.Notifier;
    this.props.root.LogService.subscribeLogs(async (msg) =>
      this.props.errorMessage(msg),
    );

    this.state = {
      autocompleteList: [],
      autocompleteActive: false,
      command: "",
      enterNewPasswords: false,
      feedbackItems: [],
      newPassword: "",
      newPasswordConfirmation: "",
    };
  }

  override async componentDidMount() {
    // TODO: v1.0.3: this should probably happen somewhere else, to prevent it from
    // happening every time the component is mounted. Maybe it should be an application state?
    // https://reactjs.org/blog/2022/03/29/react-v18.html#new-strict-mode-behaviors
    // https://reactjs.org/docs/strict-mode.html#ensuring-reusable-state
    try {
      if (!Webconsole.alreadyMounted) {
        window.addEventListener("keydown", this.globalHandleKeyPress);

        // Strict mode calls componentDidMount twice, so we check if it's already run
        Webconsole.alreadyMounted = true;
        Webconsole.focusToCommand();

        // ask for password and decrypt store
        // await this.executeCommand("lt");
      }
    } catch (e: unknown) {
      this.showError(e);
    }
  }

  private async executeCommand(command: string) {
    if (command.toLowerCase() === "cep") {
      this.setState({
        enterNewPasswords: true,
      });

      return;
    }

    await this.executeCommandInternal(command);
  }

  private async executeCommandInternal(command: string) {
    this.props.setLoading(true);
    let result: IBotCommandResult;
    try {
      result = await this.botService.sendText(command);
    } finally {
      this.props.setLoading(false);
    }

    await this.executeClientActions(result);

    this.setFeedback(command, result);
  }

  private setFeedback(command: string, result: IBotCommandResult) {
    let feedbackItems = [];
    feedbackItems.push("$ " + this.maskPassword(command));

    const feedbackLines = this.formatFeedback(result.feedback);
    feedbackItems = feedbackItems.concat(feedbackLines);
    this.setState({
      feedbackItems: feedbackItems,
    });
  }

  private maskPassword(command: string): string {
    if (command.toLowerCase().startsWith("cep ")) {
      return "cep ***** *****";
    }

    return command;
  }

  private formatFeedback(feedback: string) {
    const formattedFeedback = feedback.replaceAll("\t", "    ");
    return formattedFeedback.split("\n");
  }

  globalHandleKeyPress(event: KeyboardEvent): void {
    if (event.code === "Tab") {
      event.preventDefault();
      Webconsole.focusToCommand();
    }
  }

  handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    try {
      const command = this.state.command;
      this.setState({
        command: "",
      });
      await this.executeCommand(command);
    } catch (e: unknown) {
      this.showError(e);
    }
  };

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ command: event.currentTarget.value });
  };

  private simulateTab = async () => {
    await this.updateAutoComplete();
    await this.doAutoComplete();
    Webconsole.focusToCommand();
  };

  handleKeyUp = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    // TODO: Bug on mobile autocomplete is not trigger when typing @ or +
    const autocompleteActive =
      this.keyTriggersAutoComplete(event) || this.state.autocompleteActive;

    this.setState({
      autocompleteActive: autocompleteActive,
    });

    if (autocompleteActive) {
      await this.updateAutoComplete();
    }

    if (event.code === "Tab") {
      event.preventDefault();
      await this.doAutoComplete();
    }
  };

  private keyTriggersAutoComplete(
    event: React.KeyboardEvent<HTMLInputElement>,
  ) {
    return event.code === "Tab" || event.key === "+" || event.key === "@";
  }

  private async updateAutoComplete(commandInput?: string) {
    commandInput ??= this.state.command ?? "";

    const autocompleteKeywords =
      await this.botService.getAutocompleteKeywords(commandInput);

    const autocompleteList = this.getAutoCompleteList(
      commandInput,
      autocompleteKeywords,
    );

    this.setState({
      autocompleteList: autocompleteList,
    });
  }

  private async doAutoComplete() {
    if (this.state.autocompleteList.length === 1) {
      this.setState({
        command: this.state.autocompleteList[0],
        autocompleteList: [],
        autocompleteActive: false,
      });
    }
  }

  private getAutoCompleteList(commandInput: string, keywords: string[]) {
    return keywords.map((t) =>
      this.autoCompleteService.applyAutocomplete(commandInput, t),
    );
  }

  public static focusToCommand() {
    document.getElementById("commandfield")?.focus();
  }

  private async executeClientActions(
    botCommandResult: IBotCommandResult,
  ): Promise<IBotCommandResult> {
    const topic = botCommandResult.commandName + "-executed";
    const message = new NotifyMessage(topic);

    await this.notifier.publish(message);

    if (botCommandResult?.returnParams?.zipData) {
      BrowserFileUtil.downloadFile(
        botCommandResult.returnParams.zipData,
        botCommandResult.returnParams.zipFileName,
      );
    }

    botCommandResult = this.doCallbackAction(botCommandResult);

    return botCommandResult;
  }

  // TODO: move some of these private function to a separate class
  private doCallbackAction(
    botCommandResult: IBotCommandResult,
  ): IBotCommandResult {
    if (
      botCommandResult.callBackCommand === ImportBotCommand.staticCommandName
    ) {
      if (botCommandResult.confirmation) {
        // TODO: replace confirm popup with react modal
        // TODO: Add some more explanation to the message
        const confirm = window.confirm(
          "Importing new data deletes all existing data. Are you sure?",
        );
        if (!confirm) {
          botCommandResult.feedback = "import aborted";
          return botCommandResult;
        }
      }

      const commandText = botCommandResult.callBackCommand;
      BrowserFileUtil.uploadFile(this.onloadFunction(commandText), ".zip");
    }

    return botCommandResult;
  }

  private onloadFunction(commandText: string) {
    return async (readerEvent: ProgressEvent<FileReader>) => {
      // ArrayBuffer because we use readAsArrayBuffer in uploadFile
      const uploadedContent = readerEvent.target?.result as ArrayBuffer;
      if (!uploadedContent) {
        throw new Error("no content after uploading a file");
      }

      const zipData = new Uint8Array(uploadedContent);

      const result = await this.botService.sendTextWithAttachment(
        commandText,
        zipData,
      );

      this.setFeedback(commandText, result);
    };
  }

  private showError(e: unknown) {
    const error = e as Error;
    this.props.errorMessage(error.message);
    throw e;
  }

  private newEncryptionPasswordSubmitted = async (
    password: string,
  ): Promise<void> => {
    try {
      await this.executeCommandInternal(`cep ${password} ${password}`);
    } catch (e: unknown) {
      const error = e as Error;
      this.showError(error.message);
    }

    this.setState({
      enterNewPasswords: false,
      newPassword: "",
      newPasswordConfirmation: "",
    });
  };

  private getHelp = async () => {
    await this.executeCommand("help");
  };

  // TODO: Dead code. Use and refactor to different class or delete
  private isMobile(): boolean {
    // Use this or should we go with this regex dragon?
    // https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
    return window.matchMedia("(max-width: 767px)").matches; // Make sure to match with css .show-large
  }

  override render() {
    const { feedbackItems } = this.state;
    const feedbackList = feedbackItems.map((feedbackItem, index) => {
      if (feedbackItem == "") {
        return <li key={index}>&nbsp;</li>;
      }
      return <li key={index}>{feedbackItem}</li>;
    });

    let newPasswordFields = null;
    if (this.state.enterNewPasswords) {
      newPasswordFields = (
        <NewEncryptionPasswordInput
          onPasswordSubmitted={this.newEncryptionPasswordSubmitted}
        />
      );
    } else {
      newPasswordFields = <div />;
    }
    return (
      <div className="Webconsole">
        <ContextChooser root={this.root} />
        {newPasswordFields}

        {/* TODO: Test on chromebook. it has searcxh instead of tab */}
        <div className="keysRow show-mobile">
          <label>Mobile keys: </label>
          <button
            type="button"
            className="mobile-key"
            onClick={this.simulateTab}
          >
            tab
          </button>
        </div>
        <div className="command">
          <form onSubmit={this.handleSubmit}>
            <label>
              Command:
              <div className="commandRow">
                <div className="inputColumn">
                  <input
                    id="commandfield"
                    className="commandfield"
                    type="text"
                    value={this.state.command}
                    onChange={this.handleChange}
                    onKeyUp={this.handleKeyUp}
                    autoComplete="off"
                    list="autocompleteList"
                  />
                  <SmartButton root={this.root} command={this.state.command} />
                </div>
                <datalist id="autocompleteList">
                  {this.state.autocompleteList.map((item) => (
                    <option key={item} value={item}></option>
                  ))}
                </datalist>
                <div className="helpIcon" onClick={this.getHelp}>
                  ?
                </div>
              </div>
            </label>
          </form>
        </div>
        <ul id="feedback-array">{feedbackList}</ul>
        <div className="TaskList">
          <TaskList root={this.root} />
        </div>
      </div>
    );
  }
}

export default Webconsole;
